import React, { useReducer, useEffect, useRef } from 'react';

import AppContext from './AppContext';

const initialState = {
  portalNode: null,
  isModalActive: false,
  modal: null,
  header: null,
};

function reducer(state, action) {
  if (process.env.NODE_ENV === 'development') {
    console.info(action);
  }

  switch (action.type) {
    case 'MODAL_SHOW':
      return {
        ...state,
        isModalActive: true,
        modal: {
          ...action.modal,
        },
      };
    case 'MODAL_HIDE':
      return {
        ...state,
        isModalActive: false,
        modal: null,
      };
    case 'SET_PORTAL_NODE':
      return {
        ...state,
        portalNode: action.data,
      };
    case 'HEADER_PROPS':
      return {
        ...state,
        header: action.data,
      };
    default:
      return state;
  }
}

const AppProvider = ({ children }) => {
  const portalRef = useRef();
  const [ state, dispatch ] = useReducer(reducer, initialState);

  // make sure re-render is triggered after initial
  // render so that portalRef exists
  useEffect(() => {
    dispatch({ type: 'SET_PORTAL_NODE', data: portalRef.current });
  }, []);

  return (
    <>
      <AppContext.Provider value={{ state, dispatch }}>
        {children}
        {/* <PageTransition animate={state.isTransitioning}/> */}
      </AppContext.Provider>
      <div id="portal-root" ref={portalRef} />
    </>
  );
};

export default AppProvider;
