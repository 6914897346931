import BookStep1 from './BookStep1';
import BookStep2 from './BookStep2';
import BookStep3 from './BookStep3';
import BookStep4 from './BookStep4';

const STEPS = [
  {
    id: 'step-1',
    step: BookStep1, 
    formData: null, 
    isOpen: true,
    isDisabled: false
  },
  {
    id: 'step-2',
    step: BookStep2, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-3',
    step: BookStep3, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-4',
    step: BookStep4, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
];

export default STEPS;