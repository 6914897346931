import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Type from '../UI/Type/Type';
import Signpost from './Signpost';
import { useContactFormData } from './useContactFormData';
import * as styles from './Enquiries.module.scss';

const Referrals = (props) => {
  const { allLocations } = useContactFormData();
  const { 
    wp: { 
      acfOptionsCommonBlocks: { 
        common: { referAPatient } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query ReferralQuery {
        wp {
          acfOptionsCommonBlocks {
            common {
              referAPatient {
                registrationForm {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <div {...props}>
      <h2 className={styles.heading}>
        <em>Refer</em> your <em>patient</em>
      </h2>
      <p className={styles.lead}>
        Dowload and complete our referral form and send to the selected centre. Please note this is not a self referral form and must be completed by your referring clinician. 
      </p>

      { referAPatient?.registrationForm?.mediaItemUrl &&
        <Signpost
          title={`Printed referral form`}
          desc={`Refer a patient using the printed referral form.`}
          to={referAPatient.registrationForm.mediaItemUrl}
          icon="download"
        />
      }
      
      <Type.MetaHeading as="h2">Printed forms can be sent to:</Type.MetaHeading>

      <ul className={styles.list}>
        {allLocations.nodes.map(
          ({
            id,
            label,
            locationDetails: { address, telephone, fax, email },
          }) => (
            <li className={styles.listItem} key={id}>
              <Type.H3>{label}</Type.H3>

              {address && <p>Refer a patient to {address}.</p>}

              <p>
                {telephone && (
                  <>
                    Via phone:{' '}
                    <a href={`tel:+44${telephone.replace(/ /g, '')}`}>
                      {telephone}
                    </a>
                    <br />
                  </>
                )}
                {fax && (
                  <>
                    Via fax: {fax}
                    <br />
                  </>
                )}
                {email && (
                  <>
                    Via email: <a href={`mailto:${email}`}>{email}</a>
                  </>
                )}
              </p>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default Referrals;