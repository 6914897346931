import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

export const breakpoints = {
  xsmall: 0,
  small: 480,
  medium: 768,
  large: 1024,
  xlarge: 1200,
  xxlarge: 1440,
};

function useResponsive() {
  const [ isClient, setIsClient ] = useState(false);

  const isXSmall = useMediaQuery({
    maxWidth: breakpoints.small - 1,
  });

  const isSmall = useMediaQuery({
    minWidth: breakpoints.small,
  });

  const isMedium = useMediaQuery({
    minWidth: breakpoints.medium,
  });

  const isLarge = useMediaQuery({
    minWidth: breakpoints.large,
  });

  const isXLarge = useMediaQuery({
    minWidth: breakpoints.xlarge,
  });

  const isXxLarge = useMediaQuery({
    minWidth: breakpoints.xxlarge,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isXSmall: isClient ? isXSmall : true,
    isSmall: isClient ? isSmall : true,
    isMedium: isClient ? isMedium : false,
    isLarge: isClient ? isLarge : true,
    isXLarge: isClient ? isXLarge : false,
    isXxLarge: isClient ? isXxLarge : false,
  };
}

export default useResponsive;
