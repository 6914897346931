import CardiacScanStep1 from './CardiacScanStep1';
import CardiacScanStep2 from './CardiacScanStep2';
import CardiacScanStep3 from './CardiacScanStep3';
import CardiacScanStep4 from './CardiacScanStep4';
import CardiacScanStep5 from './CardiacScanStep5';
import CardiacScanStep6 from './CardiacScanStep6';

const STEPS = [
  {
    id: 'step-1',
    step: CardiacScanStep1, 
    formData: null, 
    isOpen: true,
    isDisabled: false
  },
  {
    id: 'step-2',
    step: CardiacScanStep2, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-3',
    step: CardiacScanStep3, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-4',
    step: CardiacScanStep4, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-5',
    step: CardiacScanStep5, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-6',
    step: CardiacScanStep6, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
];

export default STEPS;