const labels = { 
  address: 'Address',
  gender: 'Gender',
  otherGender: 'Other gender',
  firstName: "First name",
  surname: "Surname",
  dob: 'Date of Birth',
  day: 'Birth day',
  month: 'Birth month',
  year: 'Birth year',
  email: "Your email",
  telephone: "Telephone",
  nhsNum: "NHS Number",
  preferredContactMethod: "Preferred method of contact",

  optionsInterestedIn: 'I am interested in...',

  dataConsent: "I consent for my results to be shared with my preferred GP and I consent to Medical Imaging Partnership and Prime Health processing my personal data for the purpose of my care pathway",
  confirmContact: 'In order for us to process your enquiry please confirm that we can contact you regarding your referral',
  termsAndConditions: 'I have read and agree to the privacy policy and terms of business'
};

export default labels;