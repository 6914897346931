import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useContactFormData } from './useContactFormData';
import labels from './CardiacScan/labels';
import Alert, { alertLevel } from '../UI/Alert/Alert';
import convertObjectToFormData from '../../utils/convert-object-to-formdata';
import * as styles from './Enquiries.module.scss';
import STEPS from './CardiacScan/CardiacScanSteps';

const CardiacScan = ({ name = "cardiac-health-check", showTitle = true, pageContext, ...other }) => {
  const [ formName, setFormName ] = useState(name);
  const [ steps, setSteps ] = useState(cloneDeep(STEPS));
  const [ status, setStatus ] = useState(false);
  const {
    wp: {
      themeGeneralSettings: {
        siteOptions: { tel },
      },
    }
  } = useContactFormData();

  function onStepSubmit(formData, payload, stepIndex) {
    setSteps(state => {
      const newState = [ ...state ];

      newState[ stepIndex ] = {
        ...newState[ stepIndex ],
        formData
      };

      newState.forEach((step, index) => {
        step.isOpen = index === stepIndex + 1;

        if( index > stepIndex ) {
          step.formData = null;
        }

        if( index === stepIndex + 1 ) {
          step.isDisabled = false;
          step.isOpen = true;
        }
      });

      return newState;
    });

    if(payload?.formId) {
      setFormName(payload.formId);
    }
  }

  function onStepClick(stepIndex) {
    setSteps(state => {
      if(!state[ stepIndex ]) return state;

      const newState = [ ...state ];
      let disableSteps = true;

      newState[ stepIndex ].isOpen = !newState[ stepIndex ].isOpen;

      if( !newState[ stepIndex ].isOpen ) {
        disableSteps = false;
      }

      newState.forEach((step, index) => {
        if( index > stepIndex ) {
          step.isOpen = !disableSteps;
          step.isDisabled = disableSteps;
        }
      });

      return newState;
    })
  }

  useEffect(() => {
    const allFormData = steps.map(step => step.formData).filter(formData => formData);

    function submit(){
      let payload = {
        "form-name": `book-${formName}`,
        "bot-field": ``,
        "subject": `Prime Health Cardiac CT Health Check Web Referral`
      };

      steps.forEach(step => {
        payload = { ...payload, ...step.formData };
      })

      const options = {
        method: 'POST',
        body: convertObjectToFormData(payload)
      }

      fetch("", options)
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            console.error(
              `Looks like there was a problem. Status Code: ${response.status}`
            );
            
            setStatus({ isSubmitted: true, isSuccessful: false });

            return;
          }

          setStatus({ isSubmitted: true, isSuccessful: true });

          window.dataLayer.push({
            'event': 'Form Submission',
            'formType': 'Book cardiac CT health check'
          });
        })
        .catch(function (err) {
          console.error('Fetch Error :-S', err);
        });
    }

    if(steps.length && allFormData.length === steps.length) {
      submit();
    }
  }, [ steps, formName ]);

  return (
    <div {...other}>
      {showTitle &&
        <h2 className={styles.heading}>
          <em>Book</em> a <em>cardiac CT health check</em> at <em>Prime Health</em>
        </h2>
      }

      <p className={styles.lead}>
        If you would like to book an appointment for a service at one of our centres, please complete the form below, or you can call us on{' '}
        <a href={`tel:+44${`03300250150`.replace(/ /g, '')}`}>
          <strong>{`03300 250150`}</strong>
        </a>
      </p>

      {!status.isSubmitted && 
        <>
          {steps.map( ({ step: Step, isOpen, isDisabled, formData }, index) => {
            return (
              <Step 
                onSubmit={(formData, payload) => onStepSubmit(formData, payload, index)} 
                onClick={index => onStepClick(index)}
                data={formData}
                labels={labels}
                form={steps}
                isDisabled={isDisabled}
                index={index}
                number={index + 1}
                isOpen={isOpen} 
                key={index}/>
            )
          })}
        </>
      }

      {status.isSubmitted && (
          status.isSuccessful ? (
            <>
              <Alert level={alertLevel.SUCCESS}>
                Thank you for your enquiry.
              </Alert>
              <h3>What happens next</h3>               
              <p>A member of our bookings team will be in touch with you shortly via telephone to discuss with you your procedure as well as to further assess your health for us to safely carry out your procedure.</p>
              <p>A specialist doctor will review all information you provide and will determine whether or not it is safe to proceed with the procedure.</p>
              <p>Should our doctor find that it would be unsafe to proceed with the Cardiac CT Health Check based on your medical condition, you will be advised by our bookings team.</p>
            </>
          ) : (
            <Alert level={alertLevel.DANGER}>
              Looks like there was a problem, please try again later.
            </Alert>
          )  
      ) }
    </div>
  );
};

export default CardiacScan;