import React, { useState, useRef, useEffect } from 'react';

import useOnScreen from '../../hooks/useOnScreen';
import { motion } from '../UI/framer-motion-custom';

const itemTransition = {
  duration: 0.6,
  ease: 'circOut',
};

const container = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.15,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 30, transition: itemTransition },
  visible: { opacity: 1, y: 0, transition: itemTransition },
};

const AnimateReveal = ({
  as: Comp = motion.div,
  at = '-200px',
  variants = container,
  itemVariants = item,
  children,
  staggerChildren = false,
  ...other
}) => {
  const ref = useRef(null);
  const inView = useOnScreen(ref, { rootMargin: `0px 0px ${at} 0px` });
  const [ isRevealed, setRevealed ] = useState(false);

  useEffect(() => {
    if (inView) {
      setRevealed(true);
    }
  }, [ inView ]);

  return (
    <Comp
      variants={variants}
      initial="hidden"
      animate={isRevealed ? 'visible' : 'hidden'}
      ref={ref}
      {...other}
    >
      {staggerChildren
        ? React.Children.map(children, (child) => {
            return (
              child &&
              React.cloneElement(child, {
                variants: child?.props?.variants || itemVariants,
                initial: 'visible',
              })
            );
          })
        : children}
    </Comp>
  );
};

export default AnimateReveal;
