import React, {
  createContext,
  useReducer,
} from 'react';
import _uniqueId from 'lodash/uniqueId';
import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';

const DropdownContext = createContext(null);

const Dropdown = ({ children }) => {
  const initialState = {
    buttonId: _uniqueId('dropdown-button-'),
    menuId: _uniqueId('dropdown-menu-'),
    isOpen: false,
    buttonRef: null,
    buttonPosition: null,
  };

  function reducer(state, action) {
    if (process.env.NODE_ENV === 'development') {
      console.info(action);
    }

    switch (action.type) {
      case 'OPEN':
        return {
          ...state,
          isOpen: true,
          ...action.payload,
        };
      case 'CLOSE':
        return {
          ...state,
          isOpen: false,
          buttonPosition: null,
        };
      default:
        return state;
    }
  }

  const [ state, dispatch ] = useReducer(reducer, initialState);

  return (
    <DropdownContext.Provider value={{ state, dispatch }}>
      {children}
    </DropdownContext.Provider>
  );
};


export { 
  Dropdown, 
  DropdownContext, 
  DropdownButton, 
  DropdownMenu
}