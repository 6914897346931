import React from 'react';
import Link from '../UI/Link/Link';
import Icon from '../UI/Icon/Icon';
import * as styles from './Enquiries.module.scss';

const Signpost = ({ title, desc, to, onClick, icon = 'arrow-right' }) => (
  <Link className={styles.signpost} onClick={onClick} to={to}>
    <h3 className={styles.signpostTitle}>{title}</h3>
    <p className={styles.signpostDesc}>{desc}</p>
    <Icon className={styles.signpostIcon} name={icon} />
  </Link>
);

export default Signpost;

