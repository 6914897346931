import classnames from 'classnames';
import React from 'react';

import Button, { themes } from '../Button/Button';
import * as styles from './NavPanel.module.scss';

export default ({ label, description, path, isReverse = false }) => {
  return (
    <div
      className={classnames(styles.navPanel, { [ styles.isReverse ]: isReverse })}
    >
      <h2 className={styles.heading}>{label}</h2>
      <p className={styles.description}>{description}</p>
      <Button theme={themes.LINK} isReverse={isReverse} to={path}>
        Learn more
      </Button>
    </div>
  );
};
