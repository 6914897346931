import React, { useEffect, useState } from 'react';
import * as styles from '../Enquiries.module.scss';
import Form from '../../UI/Form/Form';
import PatientForm from './PatientForm/PatientForm';
import ClinicianForm from './ClinicianForm/ClinicianForm';
import Step from '../Step';
import { cloneDeep } from 'lodash';
import Alert, { alertLevel } from '../../UI/Alert/Alert';
import convertObjectToFormData from '../../../utils/convert-object-to-formdata';
import serialiseObject from '../../../utils/serialise-object';
import { graphql, useStaticQuery } from 'gatsby';
import labels from './labels';

const RequestScan = ({ showTitle = true, pageContext, ...other }) => {
  const [ formType, setFormType ] = useState(null);
  const [ showStartStep, setShowStartStep ] = useState(true);
  const [ form, setForm ] = useState([]);
  const [ status, setStatus ] = useState(false);
  const isPatientForm = formType === `Patient`;
  const formName = isPatientForm ? `request-scan-patient-form` : `request-scan-clinician-form`;
  const {
    wp: { 
      acfOptionsRequestAScan: { 
        settingsRequestAScan: {
          successMessageClinician,
          successMessagePatient
        } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query RequestScan {
        wp {
          acfOptionsRequestAScan {
            settingsRequestAScan {
              successMessageClinician
              successMessagePatient
            }
          }
        }
      }
    `
  );

  function onStepSubmit(formData, stepIndex) {
    setForm(state => {
      const newState = [ ...state ];

      newState[ stepIndex ] = {
        ...newState[ stepIndex ],
        formData
      };

      newState.forEach((step, index) => {
        step.isOpen = index === stepIndex + 1;

        if( index > stepIndex ) {
          step.formData = null;
        }

        if( index === stepIndex + 1 ) {
          step.isDisabled = false;
          step.isOpen = true;
        }
      });

      return newState;
    });
  }

  function onStepClick(stepIndex) {
    setForm(state => {
      if(!state[ stepIndex ]) return state;

      const newState = [ ...state ];
      let disableSteps = true;

      newState[ stepIndex ].isOpen = !newState[ stepIndex ].isOpen;

      if( !newState[ stepIndex ].isOpen ) {
        disableSteps = false;
      }

      newState.forEach((step, index) => {
        if( index > stepIndex ) {
          step.isOpen = !disableSteps;
          step.isDisabled = disableSteps;
        }
      });

      return newState;
    })
  }

  function handleStartingStepClick() {
    setShowStartStep(!showStartStep);
  }

  function handleFormChange(form, e){
    setForm(cloneDeep(form));
    setFormType(e.target.value);
    setShowStartStep(false);
  }

  function getStatusMessage(status){
    if( status.isSuccessful ) {
      return isPatientForm ? successMessagePatient : successMessageClinician;
    } else {
      return `Looks like there was a problem, please try again later.`;
    }
  }

  useEffect(() => {
    const allFormData = form.map(step => step.formData).filter(formData => formData);

    function submit() {
      let payload = {
        "form-name": formName,
        "bot-field": ``,
        "subject": `Prime Health Scan Web Referral (${isPatientForm ? `Patient` : `Clinician`})`
      };
  
      form.forEach(step => {
        payload = { ...payload, ...step.formData };
      })

      const options = {
        method: 'POST',
        body: isPatientForm ? 
          serialiseObject(payload) 
          : 
          convertObjectToFormData(payload),
      }

      if( isPatientForm ) {
        options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
      }

      fetch("", options)
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            console.error(
              `Looks like there was a problem. Status Code: ${response.status}`
            );
            
            setStatus({ isSubmitted: true, isSuccessful: false });

            return;
          }

          setStatus({ isSubmitted: true, isSuccessful: true });

          window.dataLayer.push({
            'event': 'Form Submission',
            'formType': 'Book scan'
          });
        })
        .catch(function (err) {
          console.error('Fetch Error :-S', err);
        });
    }

    if(form.length && allFormData.length === form.length) {
      submit();
    }
  }, [ form, formName, formType, isPatientForm ]);

  return (
    <div {...other}>
      {showTitle &&
        <h2 className={styles.heading}>
          <em>Book</em> a <em>scan</em>
        </h2>
      }

      {!status.isSubmitted ?
        <>
          <Step 
            title="Are you a patient or a clinician?" 
            labels={labels}
            onClick={handleStartingStepClick}
            form={form}
            isDisabled={false}
            index={-1}
            number={1}
            isOpen={showStartStep}
            selectedValue={formType}>
            <Form.Radio 
              label="Patient" 
              name="patientOrClinician" 
              id="patientOrClinician1" 
              value="Patient" 
              onClick={handleFormChange.bind(null, PatientForm)} 
              required/>
            <Form.Radio 
              label="Clinician" 
              name="patientOrClinician" 
              id="patientOrClinician2" 
              value="Clinician" 
              onClick={handleFormChange.bind(null, ClinicianForm)}
              required/>
          </Step> 
      
          {form.map( ({ step: Step, isOpen, isDisabled, formData }, index) => {
            return (
              <Step 
                onSubmit={formData => onStepSubmit(formData, index)} 
                onClick={index => onStepClick(index)}
                data={formData}
                labels={labels}
                form={form}
                isDisabled={isDisabled}
                index={index}
                number={index + 2}
                isOpen={isOpen} 
                key={index}/>
            )
          })}
        </>
      :
        <Alert level={status.isSuccessful ? alertLevel.SUCCESS : alertLevel.DANGER}>
          {getStatusMessage(status)}
        </Alert>    
      }
    </div>
  );
};

export default RequestScan;