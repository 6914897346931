import React from 'react';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button from '../../../UI/Button/Button';
import labels from '../labels';
import { graphql, useStaticQuery } from 'gatsby';
import useFormData from '../../../../hooks/useFormData';

const ClinicianFormStep3 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useFormData(data);

  const { 
    wp: { 
      acfOptionsRequestAScan: { 
        settingsRequestAScan: { 
          fundingOptionsClinician 
        } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query ClinicianFormStep2Query {
        wp {
          acfOptionsRequestAScan {
            settingsRequestAScan {
              fundingOptionsClinician {
                value
              }
            }
          }
        }
      }
    `
  );

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  return (
    <Step title="Payment information" selectedValue={data} {...other}>
      <Form onSubmit={handleSubmit} onChange={e => setFormData(e.target.form)}>
        <Form.Fieldset label="How will the scan be funded?">
          {fundingOptionsClinician.map(({ value }, index) => (
              <Form.Radio 
                label={value} 
                name="funding" 
                id={`funding${index}`} 
                value={value} 
                required 
                checked={formData.funding === value}
                key={value}
              />
            ))}
        </Form.Fieldset>
        
        {formData.funding === 'Private medical insurance' && (
          <>
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.insurer} name="insurer" id="firstName"/>
            </Form.Row>
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.policyNumber} name="policyNumber" id="policyNumber"/>
            </Form.Row>
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.preauthNumber} name="preauthNumber" id="preauthNumber"/>
            </Form.Row>
          </>
        )}

        <Button type="submit">Next</Button>
       </Form>
    </Step>
  )
};


export default ClinicianFormStep3;