import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion';
import '@reach/accordion/styles.css';
import classNames from 'classnames';
import React from 'react';

import Icon from '../Icon/Icon';
import { motion } from '../framer-motion-custom';
import * as styles from './CustomAccordion.module.scss';

export const CustomAccordion = ({
  className,
  isReverse,
  isBordered,
  children,
  ...other
}) => {
  const classname = classNames(styles.accordion, className, {
    [ styles.isReverse ]: isReverse,
    [ styles.isBordered ]: isBordered,
  });

  return (
    <Accordion {...other} className={classname}>
      {children}
    </Accordion>
  );
};

export const CustomAccordionItemButton = ({ children, ...other }) => {
  return (
    <AccordionButton {...other} className={styles.accordionItemButton}>
      {children}{' '}
      <Icon className={styles.accordionItemButtonArrow} name="chevron-down" />
    </AccordionButton>
  );
};

export const CustomAccordionItemHeading = ({
  headingLevel = '3',
  children,
}) => {
  let Comp = 'h' + headingLevel;
  return <Comp className={styles.accordionItemHeading}>{children}</Comp>;
};

export const CustomAccordionItem = ({ children, ...other }) => {
  return (
    <AccordionItem {...other} className={styles.accordionItem}>
      {children}
    </AccordionItem>
  );
};

export const CustomAccordionItemPanel = ({
  className = styles.accordionItemPanel,
  innerClass = styles.accordionItemPanelInner,
  children,
  isCompact = false,
  ...other
}) => {
  const innerClassName = classNames(innerClass, {
    [ styles.isCompact ]: isCompact,
  });

  const { index, isExpanded } = useAccordionItemContext();
  const variants = {
    open: {
      height: 'auto',
      display: 'block',
    },
    close: {
      height: 0,
      display: 'block',
      transitionEnd: {
        display: 'none',
      },
    },
  };

  return (
    <AccordionPanel
      className={className}
      as={motion.div}
      initial="close"
      animate={isExpanded && index !== -1 ? 'open' : 'close'}
      variants={variants}
      transition={{ duration: 0.8, type: 'tween', ease: 'anticipate' }}
      {...other}
    >
      <div className={innerClassName}>{children}</div>
    </AccordionPanel>
  );
};
