import React, {
  useRef,
  useContext,
} from 'react';
import { DropdownContext } from './Dropdown';

const DropdownButton = ({ as: Comp = 'button', children, ...other }) => {
  const ref = useRef(null);
  const { state, dispatch } = useContext(DropdownContext);

  function handleToggleClick(e) {
    dispatch({
      type: state.isOpen ? 'CLOSE' : 'OPEN',
      payload: {
        buttonRef: ref,
        buttonPosition: e.currentTarget.getBoundingClientRect(),
      },
    });
  }

  return (
    <Comp
      {...other}
      id={state.buttonId}
      type="button"
      aria-haspopup="true"
      aria-controls={state.menuId}
      aria-expanded={state.isOpen}
      onClick={handleToggleClick}
      ref={ref}
    >
      {children}
    </Comp>
  );
};

export default DropdownButton;