
import React, { useEffect, useState } from 'react';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button from '../../UI/Button/Button';
import labels from './labels';

const optionsSelfReferral = [
  { label: 'My father, uncle, brother, grandfather has/had prostate cancer', value: null },
  { label: 'I am able to carry out all normal activity without restriction', value: null },
  { label: 'I am restricted in physically strenuous activity, but able to walk and do light work', value: null },
  { label: 'I am able to walk and capable of all self-care but unable to carry out physical activity and/or work for more than a few hours during the day', value: null },
  { label: 'I am capable of only limited self-care, confined to bed or chair more than 50% of waking hours', value: null },
  { label: 'I am completely disabled. Cannot carry on any self-care. Totally confined to bed or chair.', value: null },
  { label: 'I am taking finasteride or dutsteride. Also tick if you commenced taking finasteride or dutasteride only in the last 4 weeks', value: null }, 
  { label: 'I am diabetic', value: null },
  { label: 'I have renal function deficiencies or renal disease', value: null },
];

function isChecked(formData, key, value){
  const obj = formData ? formData[ key ] : null;

  if( Array.isArray(obj) ) {
    return obj.some(v => 
      v === value
    )
  } else {
    return obj === value
  }
}

const GpReferralForm = props => {
  return (
    <>
      <Form.Row>
        <Form.File label={labels.referralLetter} id="referralLetter" name="referralLetter" type="file" accept="application/pdf, image/jpeg" required/>
      </Form.Row>
    </>
  )
}

const SelfReferralForm = ({ stepData }) => {
  return (
    <>
      <Form.Fieldset label="Ethnicity" noValidate>
       <Form.Row>
          <Form.Select
            label={labels.ethnicity}
            name="ethnicity"
            id="ethnicity"
            value={stepData.ethnicity}
            theme={fieldThemes.PRIMARY_FILLED}
            options={[
              { label: 'Please select', value: '' },
              { label: 'White', value: null },
              { label: 'Mixed / Multiple ethnic groups', value: null },
              { label: 'Asian / Asian British', value: null },
              { label: 'Black / African / Caribbean / Black British', value: null },
              { label: 'Other - please state', value: 'other' }
            ]}
            required
          />
        </Form.Row>

        {stepData.ethnicity === 'other' &&
          <Form.Row>
            <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.otherEthnicity} name="otherEthnicity" id="otherEthnicity" required/>
          </Form.Row>
        }
      </Form.Fieldset>  

      <h4>About your health</h4>

      <Form.Row>
        <Form.CheckboxList 
          label={labels.optionsSelfReferral}
          help={`Please tick only the conditions that apply to you below`}
          name="optionsSelfReferral"
          options={optionsSelfReferral.map(({ label, value }) => {
            const val = value || label;
            return { 
              label, 
              value: val,
              checked: isChecked(stepData, 'optionsSelfReferral', val)
            }
          })} 
          />
      </Form.Row>
      
      <Form.Row>
        <Form.Fieldset label="Your PSA results" noValidate>
          <p>Please indicate your result of your PSA test below and upload a copy of your test results</p>

          <Form.Fieldset label={labels.psaTestResultStatus}>
            {[
              { label: 'Normal', value: null },
              { label: 'Raised', value: null },
            ].map((option, index) => (
              <Form.Radio 
                label={option.label} 
                name="psaTestResultStatus" 
                id={`psaTestResultStatus${index}`} 
                value={option.value || option.label} 
                key={index}
              />
            ))}
          </Form.Fieldset> 

          <Form.File label={labels.psaTestResults} id="psaTestResults" name="psaTestResults" type="file" accept="application/pdf, image/jpeg" required/>
        </Form.Fieldset>
      </Form.Row>
    </>
  )
}

const BookStep5 = ({ onSubmit, data, form, labels, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });

  let isGpReferral = false;

  if(form.find(({ formData }) => formData?.referralType?.toLowerCase() === 'gp')) {
    isGpReferral = true
  }

  function removeSpacesFromFileName(file) {
    return new File([file], file.name.replace(/ /g,'-'), {
      type: file.type,
      lastModified: file.lastModified,
    });
  }

  function getFormData(form) {
    const obj = getFormDataAsObject(form);

    if( obj.ethnicity !== 'other' ) {
      delete obj.otherEthnicity;
    }

    if(obj.referralLetter) {
      obj.referralLetter = removeSpacesFromFileName(obj.referralLetter);
    }

    if(obj.psaTestResults) {
      obj.psaTestResults = removeSpacesFromFileName(obj.psaTestResults);
    }

    return obj;
  }

  function handleFormChange(e) {
    setFormData(getFormData(e.target.form));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = getFormData(e.target);
    onSubmit(formData);
  }

  useEffect(() => {
    setFormData({ ...data });
  }, [ data ])
  
  return (
    <Step title="Referral information" selectedValue={formData} labels={labels} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        {isGpReferral ?
           <GpReferralForm/>
           :
           <SelfReferralForm stepData={formData}/>
        }

        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default BookStep5;