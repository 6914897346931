const labels = { 
  centre: "Location",

  clinicType: "Clinic type",
  consultantName: "Consultant name",
  physiotherapistName: "Physiotherapist name",
  serviceDetails: "Service details",

  date: 'Date',
  preferredDate: "Preferred date",
  preferredTimeslots: "Preferred timeslot(s)",

  firstName: "First name",
  surname: "Surname",
  dob: 'Date of birth',
  email: "Email",
  telephone: "Telephone",
  message: "Message/further information"  
};

export default labels;