import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { forwardRef } from 'react';

import { isInternalLink } from '../../../utils/is-internal-link.js';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import { motion } from '../framer-motion-custom';
import * as styles from './Button.module.scss';

export const themes = {
  OUTLINE: 'themeOutline',
  PRIMARY: 'themePrimary',
  SECONDARY: 'themeSecondary',
  LINK: 'themeLink',
  LINK_SIMPLE: 'themeLinkSimple',
};

export const sizes = {
  DEFAULT: 'default',
  LARGE: 'large',
};

const Button = forwardRef(
  (
    {
      className,
      children,
      icon = 'arrow-right',
      type = 'button',
      size = sizes.DEFAULT,
      theme = themes.OUTLINE,
      hideIcon = false,
      isReverse,
      isBlock,
      isCard,
      to,
      onClick,
      ...other
    },
    ref
  ) => {
    const iconName =
      !isInternalLink(to) && !icon ? `external-link` : icon || 'arrow-right';
    const showIcon = !hideIcon && type !== 'submit' && theme !== themes.LINK;

    const buttonClass = classNames(className, {
      [ styles.button ]: theme !== themes.LINK,
      [ styles.isReverse ]: isReverse,
      [ styles.isBlock ]: isBlock,
      [ styles.hasIcon ]: showIcon,
      [ styles.isCard ]: isCard,
      [ styles[ theme ] ]: !!theme,
      [ styles[ `size${capitalize(size)}` ] ]: !!size,
    });

    if (type === 'submit' || onClick) {
      return (
        <motion.button
          {...other}
          className={buttonClass}
          type={type}
          onClick={onClick}
          ref={ref}
        >
          {children}{' '}
          {showIcon && <Icon className={styles.icon} name={iconName} />}
        </motion.button>
      );
    } else {
      return (
        <Link {...other} className={buttonClass} to={to} ref={ref}>
          {children}{' '}
          {showIcon && <Icon className={styles.icon} name={iconName} />}
        </Link>
      );
    }
  }
);

export default Button;
