import { createDomMotionComponent, useReducedMotion, AnimatePresence as AnimatePresenceUnwrapped } from 'framer-motion';
import React, { forwardRef } from 'react';

/** IE 11 supported version of the motion component */
const components = [
  'div',
  'section',
  'article',
  'aside',
  'form',
  'a',
  'button',
  'p',
  'span',
  'ul',
  'li',
  'nav',
  'small',
  'h1',
  'h2',
  'h3',
  'h4',
];

const withReducedMotion = (WrappedComponent, tag) => {
  return forwardRef((props, ref) => {
    const shouldReduceMotion = useReducedMotion();
    const Comp = shouldReduceMotion ? tag : WrappedComponent;
    const {
      initial,
      animate,
      variants,
      exit,
      layout, 
      transition,
      dragConstraints, 
      dragDirectionLock, 
      dragElastic, 
      drag,
      inherit,
      custom,
      onAnimationComplete,
      ...filteredProps
    } = props;

    let styles = null;

    if( shouldReduceMotion && animate ) {
      if( typeof animate === 'string' && variants && typeof variants[ animate ] === 'object' ) {
        styles = variants[ animate ];
      } else if ( typeof animate === 'object' ) {
        styles = animate;
      }
    }

    return <Comp {...shouldReduceMotion ? filteredProps : props} style={styles} ref={ref} />;
  });
};

export const motion = (() => {
  const comps = {};
  
  components.forEach((tag) => {
    const Comp = createDomMotionComponent( tag );
    comps[ tag ] = withReducedMotion( Comp, tag );
  });

  return comps;
})();

export const AnimatePresence = props => {
  const shouldReduceMotion = useReducedMotion();
  const {
    as,
    initial,
    custom,
    exitBeforeEnter,
    onExitComplete,
    ...filteredProps
  } = props;

  return shouldReduceMotion ? <React.Fragment {...filteredProps}/> : <AnimatePresenceUnwrapped {...props}/>;
};

export * from 'framer-motion';
