import React, { useEffect, useRef, useContext, useCallback } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { globalHistory } from '@reach/router';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import AppContext from '../../../context/AppContext';
import useEventListener from '../../../hooks/useEventListener';
import Icon from '../Icon/Icon.js';
import { motion, AnimatePresence } from '../framer-motion-custom';
import * as styles from './Flyout.module.scss';

export const flyoutThemes = {
  DEFAULT: 'themeDefault',
  PRIMARY: 'themePrimary',
};

const Flyout = ({
  children,
  triggerRef,
  showCloseBtn = true,
  isScrollable = true,
  isActive = false,
  onCloseClick,
  theme = flyoutThemes.DEFAULT,
}) => {
  const app = useContext(AppContext);
  const ref = useRef(null);
  const historyRef = useRef(null);

  const innerClass = classNames(styles.inner, {
    [ styles.innerScrollable ]: isScrollable,
    [ styles[ theme ] ]: !!theme,
  });

  const handleDocumentClick = useCallback(
    (e) => {
      if (!isActive) return;

      const isWithinModal = ref.current?.contains(e.target);
      const isWithinTrigger = triggerRef?.current?.contains(e.target);

      if (!isWithinModal && !isWithinTrigger && isActive) {
        onCloseClick();
      }
    },
    [ isActive, onCloseClick, triggerRef ]
  );

  const handleKeyup = useCallback(
    (e) => {
      if (!isActive) return;

      const key = e.which || e.keyCode;

      // Esc key
      if (key === 27) {
        onCloseClick();

        if (triggerRef) {
          triggerRef.current.focus();
        }
      }
    },
    [ isActive, onCloseClick, triggerRef ]
  );

  function handleFocusLockDeactivation(){
    window.setTimeout(() => triggerRef.current.focus(), 0);
  }

  useEventListener('mouseup', handleDocumentClick);
  useEventListener('keyup', handleKeyup);

  // Set focus
  useEffect(() => {
    if (isActive) {
      ref.current.focus();
    }
  }, [ isActive, ref ]);

  // Hide overflow if active
  useEffect(() => {
    if (isActive) {
      document.body.style.paddingRight =
        document.body.offsetWidth - document.body.clientWidth;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    };
  }, [ isActive ]);

  // Close on unload
  useEffect(() => {
    return onCloseClick;
  }, [ onCloseClick ]);

  // Close on route change
  useEffect(() => {
    historyRef.current = globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && isActive) {
        onCloseClick();
      }
    });

    return historyRef.current;
  }, [ isActive, onCloseClick ]);

  return app.state.portalNode
    ? ReactDOM.createPortal(
        <AnimatePresence>
          {isActive && (
            <FocusLock onDeactivation={handleFocusLockDeactivation}>
              <motion.div
                className={styles.flyout}
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0, x: '100%', transition: { duration: 0.3 } }}
                transition={{ type: 'spring', damping: 18 }}
                role="dialog"
                aria-modal="true"
                tabIndex={-1}
                ref={ref}
              >
                {showCloseBtn && (
                  <button
                    className={styles.close}
                    type="button"
                    onClick={onCloseClick}
                    aria-label="Close"
                  >
                    <Icon name="x" />
                  </button>
                )}

                <AnimatePresence initial={false} exitBeforeEnter>
                  <motion.div
                    className={innerClass}
                    initial={{ translateX: '100%' }}
                    animate={{ translateX: '0%' }}
                    exit={{ translateX: '100%', transition: { duration: 0.3 } }}
                    key={uniqueId()}
                    transition={{ type: 'spring', damping: 18 }}
                  >
                    {children}
                  </motion.div>
                </AnimatePresence>
              </motion.div>
              <motion.div
                className={styles.overlay}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', damping: 18 }}
              ></motion.div>
            </FocusLock>
          )}
        </AnimatePresence>,
        app.state.portalNode
      )
    : null;
};

Flyout.propTypes = {};

export default Flyout;
