
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { subYears } from 'date-fns';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button from '../../UI/Button/Button';
import labels from './labels';

const optionsGender = [
  { label: 'Male (including trans man)', value: null },
  { label: 'Female (including trans woman)', value: null },
  { label: 'Non-binary', value: null },
  { label: 'Other (not listed)', value: 'other' },
];

const optionsImplant = [
  { label: 'Aneurysm clip/cochlear implant/programmable neurostimulator', value: null },
  { label: 'Programmable hydrocephalus shunt ', value: null },
  { label: 'Has (or ever had) pacemaker or ICD', value: null }
];

const minAge = 18;
const maxAge = 110;

const BookStep3 = ({ onSubmit, data, form, labels, ...other }) => {
  const [ birthDate, setBirthDate ] = useState(null);
  const [ formData, setFormData ] = useState({ ...data });

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  function handleBirthDateChange(date, e){
    setBirthDate(date);
  }

  function handleFormChange(e) {
    const obj = getFormDataAsObject(e.target.form);

    if( obj.gender !== 'other' ) {
      delete obj.otherGender;
    }

    setFormData(obj);
  }

  function isChecked(value){
    if(  Array.isArray( formData?.implants ) ) {
      return formData?.implants?.some(item => 
        item === value
      )
    } else {
      return formData?.implants === value
    }
  }
  
  return (
    <Step title="Your details" selectedValue={formData} labels={labels} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>    
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.firstName} name="firstName" id="firstName" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.surname} name="surname" id="surname" required/>
        </Form.Row>
        <Form.Fieldset label="Gender">
          {optionsGender.map((option, index) => (
            <Form.Radio 
              label={option.label} 
              name="gender" 
              id={`gender${index}`} 
              value={option.value || option.label} 
              required 
              key={index}
            />
          ))}

          {formData.gender === 'other' &&
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={'Other gender'} name="otherGender" id="otherGender" required/>
            </Form.Row>
          }
        </Form.Fieldset>  

        <Form.Row>
          <Form.DatePicker 
            theme={fieldThemes.PRIMARY_FILLED} 
            label={labels.dob}
            name="dob" 
            id="dob" 
            selected={birthDate} 
            onChange={handleBirthDateChange} 
            minDate={subYears(new Date(), maxAge)}
            maxDate={subYears(new Date(), minAge)}
            dateFormat="d MMMM yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            required />
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.nhsNum} name="nhsNum" id="nhsNum"/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.email} name="email" id="email" type="email" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.telephone} name="telephone" id="telephone" required/>
        </Form.Row>
        <Form.Row>
          <Form.TextArea theme={fieldThemes.PRIMARY_FILLED} label={labels.address} name="address" id="address"/>
        </Form.Row> 
        
        <Form.CheckboxList 
          label={"Do you have any of the following:"}
          name="implants"
          options={optionsImplant.map(({ label, value }) => {
            const val = value || label;

            return { 
              label, 
              value: val,
              checked: isChecked(val)
            }
          })}
          noValidate
          />
        
        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default BookStep3;