import React, { useState } from 'react';
import Button, { sizes as buttonSizes, themes as buttonThemes } from '../UI/Button/Button';
import Form, { themes as fieldThemes } from '../UI/Form/Form';
import { useContactFormData } from './useContactFormData';
import * as styles from './Enquiries.module.scss';

const Contact = ({ name = "default", pageContext, isStandalone = true, ...other }) => {
  const {
    wp: {
      themeGeneralSettings: {
        siteOptions: { tel },
      },
    },
    allLocations
  } = useContactFormData();
  const [ formName, setFormName ] = useState(name);

  return (
    <div {...other}>
      {isStandalone &&
        <>
          <h2 className={styles.heading}>
            <em>General</em> enquiry
          </h2>
          
        </>
      }

      <p className={styles.lead}>
          Whether you have a general enquiry or are enquiring about the services available at one of our centres, we'd love to hear from you. Please complete the form below, or you can call us on{' '}
          <a href={`tel:+44${tel.replace(/ /g, '')}`}>
            <strong>{tel}</strong>
          </a>
        </p>

      <Form
        className={isStandalone ? styles.form : null}
        name={`general-${formName}`}
        successMessage={`Many thanks for your enquiry. We will be in touch as soon as we can. If your enquiry is urgent please call ${tel}.`}
      >
        <Form.Row>
          <Form.Input
            theme={fieldThemes.PRIMARY_FILLED}
            label="Name"
            name="name"
            required
          />
        </Form.Row>
        <Form.Row>
          <Form.Input
            theme={fieldThemes.PRIMARY_FILLED}
            label="Email"
            name="email"
            type="email"
            required
          />
        </Form.Row>
        <Form.Row>
          <Form.Input
            theme={fieldThemes.PRIMARY_FILLED}
            label="Telephone"
            name="telephone"
            type="tel"
          />
        </Form.Row>
        <Form.Row>
          <Form.Select
            theme={fieldThemes.PRIMARY_FILLED}
            label="Centre"
            name="centre"
            value={pageContext?.location}
            onChange={ (value, e) => {
              const option = e.target.options[ e.target.selectedIndex ];
              setFormName( option.dataset.formId || 'default' );
            }}
            options={[
              { label: 'Please select', value: '' },
              ...allLocations.nodes.map( node => ({ 
                'label': node.label, 
                'value': node.value, 
                'data-form-id': node.locationDetails.formId
              })),
              { label: 'Unknown', value: 'Unknown' },
            ]}
          />
        </Form.Row>
        <Form.Row>
          <Form.TextArea
            theme={fieldThemes.PRIMARY_FILLED}
            label="What is the nature of your enquiry?"
            name="message"
            rows="4"
            required
          />
        </Form.Row>

        <Button
          theme={buttonThemes.PRIMARY}
          size={buttonSizes.LARGE}
          type="submit"
          isBlock
        >
          Send enquiry
        </Button>
      </Form>
    </div>
  );
};

export default Contact;