import React from 'react';
import Step from '../../Step';
import Form from '../../../UI/Form/Form';
import { graphql, useStaticQuery } from 'gatsby';

const PatientFormStep4 = ({ onSubmit, data, form, ...other }) => {
  const { 
    wp: { 
      acfOptionsRequestAScan: { 
        settingsRequestAScan: { 
          scanTypesPatient 
        } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query PatientFormStep4Query {
        wp {
          acfOptionsRequestAScan {
            settingsRequestAScan {
              scanTypesPatient {
                locations {
                  ... on WpLocation {
                    id
                    title
                  }
                }
                type
              }
            }
          }
        }
      }
    `
  );
  const selectedLocation = form.find(({ formData }) => formData?.centre)?.formData?.centre;
  const scanTypes = scanTypesPatient.filter(scanType => {
    return scanType.locations.some(({ title }) => 
      title === selectedLocation
    );
  });

  return (
    <Step title="What sort of scan you do need?" selectedValue={data?.scanType} {...other}>
      {scanTypes.map(({ type }, index) => (
          <Form.Radio 
            label={type} 
            name="scanType" 
            id={`scanType${index}`} 
            value={type} 
            required 
            checked={data?.scanType === type}
            onChange={e => onSubmit({ [ e.target.name ]: e.target.value }) }
            onClick={e => onSubmit({ [ e.target.name ]: e.target.value }) }
            key={index}
          />
      ))}
    </Step>
  )
};


export default PatientFormStep4;