import classNames from 'classnames';
import React from 'react';

import * as styles from './Columns.module.scss';

const Columns = ({
  children,
  showBorders = true,
  isReverse = true,
  count,
  containerTag: Container = 'div',
  itemTag: Item = 'div',
  ...other
}) => {
  const className = classNames(styles.columns, other.className, {
    [ styles[ `cols${count}` ] ]: !!count,
    [ styles.showBorders ]: showBorders,
    [ styles.isReverse ]: isReverse,
  });

  return (
    <Container className={className}>
      {React.Children.map(children, (child, index) => (
        <Item className={styles.item} key={index}>
          {child}
        </Item>
      ))}
    </Container>
  );
};

export default Columns;
