
import React, { useState } from 'react';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button, { themes as ButtonThemes }  from '../../UI/Button/Button';
import * as utilities from '../../../styles/utilities.module.scss';
import Type from '../../UI/Type/Type';

const optionsMedicalCondition = [
  { label: 'Unstable angina or MI (Chest pain or Heart Attack)', value: null },
  { label: 'History of heart failure', value: null },
  { label: 'Coronary Artery Disease', value: null },
  { label: 'History of PCI, CABG (procedure done to your heart like Angioplasty / Stenting / Bypass Graft)', value: null },
  { label: 'Stroke or TIA (\'mini stroke\')', value: null },
  { label: 'Aortic Stenosis', value: null },
  { label: 'Family history of coronary artery disease', value: null },
  { label: 'Congenital heart disease', value: null },
  { label: 'Uncontrolled Hypertension ', value: null },
  { label: 'Diabetes', value: null },
  { label: 'Severe Kidney Disease (eGFR < 30)', value: null },
  { label: 'Asthma or COPD', value: null },
  { label: 'Severe contrast/dye allergy', value: null },
  { label: 'Severe claustrophobia', value: null }
];

function isChecked(formData, key, value){
  const obj = formData ? formData[ key ] : null;

  if( Array.isArray(obj) ) {
    return obj.some(v => 
      v === value
    )
  } else {
    return obj === value
  }
}
const BookStep5 = ({ onSubmit, data, form, labels, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });

  function getFormData(form) {
    const obj = getFormDataAsObject(form);
    return obj;
  }

  function handleFormChange(e) {
    setFormData(getFormData(e.target.form));
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }
  
  return (
    <Step title="Health information" selectedValue={formData} labels={labels} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        <Type.H4 className={utilities.mb20}>Please tell us your Medical History</Type.H4>

        <Form.Row className={utilities.mb40}>
          <p className={utilities.mb20}>
            <em>Please tick statements that apply to you below</em>
          </p>
          <Form.Checkbox label={labels.hasChestPain} name="hasChestPain" id="hasChestPain"/>
          <Form.Checkbox label={labels.hasBlackOutsOrLossOfConsciousness} name="hasBlackOutsOrLossOfConsciousness" id="hasBlackOutsOrLossOfConsciousness"/>
          <Form.Checkbox label={labels.hasShortnessOfBreathWhenFlat} name="hasShortnessOfBreathWhenFlat" id="hasShortnessOfBreathWhenFlat"/>
        </Form.Row>

        <Form.Row>
          <Form.TextArea theme={fieldThemes.PRIMARY_FILLED} label={labels.medications} name="medications" id="medications"/>
        </Form.Row>

        <Form.Row className={utilities.mb40}>
          <Form.TextArea theme={fieldThemes.PRIMARY_FILLED} label={labels.restingHeartRateAndBloodPressure} name="restingHeartRateAndBloodPressure" id="restingHeartRateAndBloodPressure"/>
        </Form.Row>

        <Form.Fieldset label={labels.nhsCardiacInvestigation}>
          <Form.Radio label={`Yes`} name="nhsCardiacInvestigation" id="nhsCardiacInvestigation1" value="Yes" required/>
          <Form.Radio label={`No`} name="nhsCardiacInvestigation" id="nhsCardiacInvestigation2" value="No" required/>
        </Form.Fieldset>

        <Form.CheckboxList 
          label={labels.optionsMedicalCondition}
          help={`Please tick only the conditions that apply to you below`}
          name="optionsMedicalCondition"
          options={optionsMedicalCondition.map(({ label, value }) => {
            const val = value || label;
            return { 
              label, 
              value: val,
              checked: isChecked(formData, 'optionsMedicalCondition', val)
            }
          })} 
          />

        <Form.Fieldset label={labels.intoleranceToBetaBlocker}>
          <Form.Radio label={`Yes`} name="intoleranceToBetaBlocker" id="intoleranceToBetaBlocker1" value="Yes" required/>
          <Form.Radio label={`No`} name="intoleranceToBetaBlocker" id="intoleranceToBetaBlocker2" value="No" required/>
        </Form.Fieldset>

        <Form.Fieldset label={labels.intoleranceToGTN}>
          <Form.Radio label={`Yes`} name="intoleranceToGTN" id="intoleranceToGTN1" value="Yes" required/>
          <Form.Radio label={`No`} name="intoleranceToGTN" id="intoleranceToGTN2" value="No" required/>
        </Form.Fieldset>

        <Form.Fieldset label={labels.takingVerapamil}>
          <Form.Radio label={`Yes`} name="takingVerapamil" id="takingVerapamil1" value="Yes" required/>
          <Form.Radio label={`No`} name="takingVerapamil" id="takingVerapamil2" value="No" required/>
        </Form.Fieldset>

        <Form.Fieldset label={labels.takingDiltiazem}>
          <Form.Radio label={`Yes`} name="takingDiltiazem" id="takingDiltiazem1" value="Yes" required/>
          <Form.Radio label={`No`} name="takingDiltiazem" id="takingDiltiazem2" value="No" required/>
        </Form.Fieldset>

        <Form.Fieldset label={labels.isPregnant}>
          <Form.Radio label={`Yes`} name="isPregnant" id="isPregnant1" value="Yes" required/>
          <Form.Radio label={`No`} name="isPregnant" id="isPregnant2" value="No" required/>
        </Form.Fieldset>

        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default BookStep5;