import React, { useEffect, useState } from 'react';
import Step from '../Step';
import Form from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button from '../../UI/Button/Button';
import Alert, { alertLevel } from '../../UI/Alert/Alert';
import labels from './labels';
import * as styles from '../../../styles/utilities.module.scss'

const BookStep1 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });

  function handleSubmit(e) {
    e.preventDefault();    
    onSubmit(formData);
  }

  function handleFormChange(e) {
    setFormData(getFormDataAsObject(e.target.form));
  }

  useEffect(() => {
    setFormData({ ...data });
  }, [ data ]);

  return (
    <Step title="Confirmations" selectedValue={formData} {...other}>
       <Form onSubmit={handleSubmit} onChange={handleFormChange}>
          <Alert className={styles.mb20}>Please note: this health check service is not currently available under Private Medical Insurance </Alert>
          <Alert level={alertLevel.DANGER}>If you have been treated for a urinary tract infection or prostatitis in the last 6 weeks DO NOT proceed with this request at this stage. Please undertake a new PSA test in 6 weeks time if this is the case.</Alert>

          <Form.Row>
            <Form.Checkbox label={labels.confirmNoInfection} name="confirmNoInfection" id="confirmNoInfection" required/>
            <Form.Checkbox label={labels.dataConsent} name="dataConsent" id="dataConsent" required/>
            <Form.Checkbox label={labels.prostateScreenAware} name="prostateScreenAware" id="prostateScreenAware" required/>
            <Form.Checkbox label={labels.confirmContact} name="confirmContact" id="confirmContact" required/>
            <Form.Checkbox 
                label={
                  <>I have read and agree to the <a href="/privacy-policy/" target='_blank'>privacy policy</a> and <a href="/terms-of-business/" target='_blank'>terms of business</a>.</>
                }
                name="termsAndConditions" 
                id="termsAndConditions"
                required/>  
          </Form.Row>          

          <Button type="submit">Next</Button>
        </Form>
    </Step>
  )
};


export default BookStep1;