
import React, { useState } from 'react';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button from '../../UI/Button/Button';
import labels from './labels';
import * as utilities from '../../../styles/utilities.module.scss';

const BookStep4 = ({ onSubmit, data, form, labels, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  function handleFormChange(e) {
    setFormData(getFormDataAsObject(e.target.form));
  }

  function isChecked(value){
    if(  Array.isArray( formData?.implants ) ) {
      return formData?.implants?.some(item => 
        item === value
      )
    } else {
      return formData?.implants === value
    }
  }
  
  return (
    <Step title="Your preferred GP Practice Details" selectedValue={formData} labels={labels} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>   
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.gpName} name="gpName" id="gpName" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.gpEmail} name="gpEmail" id="gpEmail" type="email" required/>
        </Form.Row>
        <Form.Row>
          <Form.TextArea theme={fieldThemes.PRIMARY_FILLED} label={labels.gpAddress} name="gpAddress" id="gpAddress" required/>
        </Form.Row> 
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.gpTel} name="gpTel" id="gpTel"/>
        </Form.Row>
        <Form.Fieldset label={labels.practiceType}>
          <Form.Radio 
              label={labels.practiceTypeNhs} 
              name="practiceType" 
              id={`practiceType1`} 
              value={labels.practiceTypeNhs} 
              required 
            />
          <Form.Radio 
              label={labels.practiceTypePrivate} 
              name="practiceType" 
              id={`practiceType2`} 
              value={labels.practiceTypePrivate} 
              required 
            />
        </Form.Fieldset>  

        <Form.Fieldset label={labels.referralTitle}>
          <Form.Radio 
              label={labels.referralGP} 
              name="referralType" 
              id={`referralType1`} 
              value={`GP`} 
              required 
            />
          <p class={utilities.mb20}><small>You do not need to upload a PSA test result if you have a referral letter from a GP or Consultant</small></p>

          <Form.Radio 
              label={labels.referralSelf} 
              name="referralType" 
              id={`referralType2`} 
              value={`Self`} 
              required 
            />
          
          <p><small>You will be require to upload a PDF/JPEG version of your PSA Test Result OR GP Referral to complete this booking request.</small></p>
        </Form.Fieldset>   
        
        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default BookStep4;