import classNames from 'classnames';
import React from 'react';

import Link from '../Link/Link';
import * as styles from './NavList.module.scss';

const NavList = ({ nodes, isPrimary, isReverse, showActive }) => {
  const classnames = classNames(styles.navlist, {
    [ styles.isPrimary ]: isPrimary,
    [ styles.isReverse ]: isReverse,
    [ styles.showActive ]: showActive,
  });

  return (
    <ul className={classnames}>
      {nodes.map((node) => {
        const path = (node?.connectedNode?.node?.uri ?? node.url) || node.path;

        return (
          <li className={styles.item} key={node.id}>
            <Link
              className={styles.link}
              to={path}
              {...(showActive ? { activeClassName: styles.linkActive } : {})}
            >
              {node.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

NavList.propTypes = {};

export default NavList;
