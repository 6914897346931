
import React, { useState } from 'react';
import { subYears } from 'date-fns';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button, { themes as ButtonThemes } from '../../UI/Button/Button';
import labels from './labels';

const minAge = 16;
const maxAge = 110;

const BookStep4 = ({ onSubmit, data, form, ...other }) => {
  const [ birthDate, setBirthDate ] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    const formData = getFormDataAsObject(e.target);
    onSubmit(formData);
  }

  function handleBirthDateChange(date, e){
    setBirthDate(date);
  }
  
  
  return (
    <Step title="Your details" {...other}>
      <Form onSubmit={handleSubmit}>      
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.firstName} name="firstName" id="firstName" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.surname} name="surname" id="surname" required/>
        </Form.Row>
        <Form.Row>
          <Form.DatePicker 
            theme={fieldThemes.PRIMARY_FILLED} 
            label={labels.dob}
            name="dob" 
            id="dob" 
            selected={birthDate} 
            onChange={handleBirthDateChange} 
            minDate={subYears(new Date(), maxAge)}
            maxDate={subYears(new Date(), minAge)}
            dateFormat="d MMMM yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            required />
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.email} name="email" id="email" type="email" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.telephone} name="telephone" id="telephone" required/>
        </Form.Row>
        <Form.Row>
          <Form.TextArea label={labels.message} name="message" id="message" rows="4" theme={fieldThemes.PRIMARY_FILLED}/>
        </Form.Row>
        <Form.Row>
          <Form.Checkbox label="I am happy to occasionally be informed you of new services, exclusive price offers and educational events." name="marketing" id="marketing"/>
          <Form.Checkbox label={<>I agree to the <a href="/privacy-policy/" target='_blank'>privacy policy</a>.</>} name="privacyPolicy" id="privacyPolicy" required/>
        </Form.Row>
        
        <Button theme={ButtonThemes.PRIMARY} type="submit">Book</Button>
      </Form>
    </Step>
  )
};

export default BookStep4;