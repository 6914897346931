import classNames from 'classnames';
import React from 'react';

import * as styles from './Hamburger.module.scss';

const Hamburger = React.forwardRef(
  ({ label = 'Menu', onClick, isActive, isFixed }, ref) => {
    const className = classNames(styles.hamburger, {
      [ styles.isFixed ]: isFixed,
    });

    return (
      <button
        className={className}
        type="button"
        aria-label={label}
        onClick={onClick}
        aria-expanded={isActive}
        ref={ref}
      />
    );
  }
);

export default Hamburger;
