import React from 'react';
import Step from '../../Step';
import Form from '../../../UI/Form/Form';
import { graphql, useStaticQuery } from 'gatsby';

const PatientFormStep3 = ({ onSubmit, data, form, ...other }) => {
  const { 
    allWpLocation: { 
      nodes
    }
  } = useStaticQuery(
    graphql`
      query PatientFormStep3Query {
        allWpLocation {
          nodes {
            id
            title
          }
        }
      }
    `
  );
  
  return (
    <Step title="At which Prime Health centre would you like your scan?" selectedValue={data?.centre} {...other}>
      {nodes.map((location, index) => (
          <Form.Radio 
            label={location.title} 
            name="centre" 
            id={`centre${index}`} 
            value={location.title} 
            required 
            checked={data?.centre === location.title}
            onChange={e => onSubmit({ [ e.target.name ]: e.target.value }) }
            onClick={e => onSubmit({ [ e.target.name ]: e.target.value }) }
            key={index}
          />
      ))}
    </Step>
  )
};


export default PatientFormStep3;