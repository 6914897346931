import React, { useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button from '../../../UI/Button/Button';
import getFormDataAsObject from '../../../../utils/get-form-data-as-object';

const PatientFormStep6 = ({ onSubmit, data, form, ...other }) => {
  const [ date, setDate ] = useState(null);
  const [ isAsap, setIsAsap ] = useState(null);
  const [ formData, setFormData ] = useState({ ...data });
  const { 
    allWpLocation: {
      nodes: locations
    }
  } = useStaticQuery(
    graphql`
      query PatientFormStep5Query {
        allWpLocation {
          nodes {
            title
            locationDetails {
              timeSlots {
                label
                timeSlot {
                  from
                  until
                }
              }
            }
          }
        }
      }
    `
  );
  
  const times = useMemo(() => {
    const selectedLocation = form.find(({ formData }) => formData?.centre)?.formData?.centre;
    const match = locations.find(({ title }) => {
      return title === selectedLocation;
    });
    return match?.locationDetails.timeSlots || [];
  }, [ form, locations ]);

  function getFormData(form) {
    const obj = getFormDataAsObject(form);
    delete obj.when;
    return obj;
  }
  
  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(getFormData(e.target));
  }

  function handleFormChange(e) {
    setFormData(getFormData(e.target.form));
  }

  function handleWhenChange(isAsap, e){
    setIsAsap(isAsap);
    
    if(isAsap) {
      onSubmit({ preferredDate: 'ASAP' });
    }
  }

  function handleDateChange(date, e){
    setDate(date);
  }

  function isChecked(value){
    if( Array.isArray( formData?.preferredTimeslots ) ) {
      return formData?.preferredTimeslots?.some(timeslot => 
        timeslot === value
      )
    } else {
      return formData?.preferredTimeslots === value
    }
  }

  useEffect(() => {
    setFormData({ ...data });
  }, [ data ])

  return (
    <Step title="When would you like your scan?" selectedValue={data} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        <Form.Row>
          <Form.Radio 
            label="ASAP" 
            id="when1" 
            name="when"
            value="asap"
            onChange={handleWhenChange.bind(null, true)} 
            onClick={handleWhenChange.bind(null, true)}
            defaultChecked={isAsap}
            required
            />
          <Form.Radio 
            label="Specify a date and time" 
            id="when2" 
            name="when"
            onChange={handleWhenChange.bind(null, false)} 
            onClick={handleWhenChange.bind(null, false)}
            defaultChecked={isAsap === false}
            required
            />
        </Form.Row>

        {isAsap === false &&
          <>
            <p>Please note that we cannot guarantee your preferred day and time slot, but will do our best to accommodate your preference.</p>

            <Form.Row>
              <Form.DatePicker 
                theme={fieldThemes.PRIMARY_FILLED} 
                label="Date"
                name="preferredDate" 
                id="date" 
                selected={date} 
                onChange={handleDateChange} 
                dateFormat="d MMMM yyyy"
                required />
            </Form.Row>

            <Form.Fieldset label="Select preferred time slot">
              {
                times.map((group, index) => {
                  return group.timeSlot ? (
                    <Form.CheckboxList 
                      label={group.label}
                      name="preferredTimeslots"
                      listId={index}
                      options={group.timeSlot.map(({ from, until }) => {
                        const label = `${from} - ${until}`;
                        const value = `${group.label}: ${label}`;

                        return { 
                          label, 
                          value,
                          checked: isChecked(value)
                        }
                      })} 
                      key={index}
                      noValidate
                      required={!formData.preferredTimeslots}
                      />
                  ) : null
                })
              }
            </Form.Fieldset>

            <Button type="submit">Next</Button>
          </>
        }

        
      </Form>
    </Step>
  )
};

export default PatientFormStep6;