// extracted by mini-css-extract-plugin
export var body = "NavFlyout-module--body--7da11";
export var footer = "NavFlyout-module--footer--578c8";
export var nav = "NavFlyout-module--nav--c4d94";
export var navArrow = "NavFlyout-module--navArrow--2f6aa";
export var navItem = "NavFlyout-module--navItem--668ac";
export var navLink = "NavFlyout-module--navLink--03af8";
export var navLinkSecondary = "NavFlyout-module--navLinkSecondary--495bc NavFlyout-module--navLink--03af8";
export var navPanel = "NavFlyout-module--navPanel--6006d";
export var navPanelInner = "NavFlyout-module--navPanelInner--90562";
export var panel = "NavFlyout-module--panel--8b759";