import { useState, useEffect } from 'react';

const DEFAULTS = {
  rootMargin: '0px', 
  threshold: 0, 
  defaultState: false
};

function useOnScreen(ref, options ) {
  const { rootMargin, threshold, defaultState } = Object.assign({}, DEFAULTS, options);
  // State and setter for storing whether element is visible
  const [ isIntersecting, setIntersecting ] = useState(defaultState);

  useEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver(
      ([ entry ]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
        threshold,
      }
    );
    if (element) {
      observer.observe(element);
    }
    return () => {
      observer.unobserve(element);
    };
  }, [ ref, rootMargin, threshold ]);

  return isIntersecting;
}

export default useOnScreen;
