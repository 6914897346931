// extracted by mini-css-extract-plugin
export var accreds = "Footer-module--accreds--a76ef";
export var bottomRow = "Footer-module--bottomRow--1a135";
export var contact = "Footer-module--contact--2fc21";
export var contactAddress = "Footer-module--contactAddress--49cfd";
export var contactDetails = "Footer-module--contactDetails--e5c43";
export var cqc = "Footer-module--cqc--28826";
export var footer = "Footer-module--footer--2d913";
export var navigation = "Footer-module--navigation--6d64c";
export var newsletter = "Footer-module--newsletter--b909e";
export var sectionTitle = "Footer-module--sectionTitle--a9b1b";
export var topRow = "Footer-module--topRow--207ce";
export var watermark = "Footer-module--watermark--f111f";