import React from 'react';
import { useState } from 'react';
import { motion } from '../framer-motion-custom';
import Cookies from 'js-cookie';

import Icon from '../Icon/Icon';
import * as styles from './CookieBar.module.scss';
import Link from '../Link/Link';

const variants = {
  hidden: { opacity: 0, y: '100%', visibility: 'hidden' },
  visible: {
    opacity: 1,
    y: 0,
    visibility: 'visible'
  },
};

const hasConsented = !!Cookies.get('cookieconsent');

const CookieBar = () => {
  const [ isVisible, setVisible ] = useState( !hasConsented );
  
  function handleCloseClick(){
    setVisible(false);
    Cookies.set('cookieconsent', 1, { expires: 365 })
  }

  return (
    <motion.div className={styles.container} initial="hidden" animate={isVisible ? 'visible' : 'hidden'} variants={variants} role="alert">
      <p className={styles.message}>This website uses cookies to ensure you get the best experience on our website. <Link to="/cookie-policy/">Learn more</Link></p>
      <button
        className={styles.close}
        type="button"
        onClick={handleCloseClick}
        aria-label="Close"
      >
        <Icon name="x" />
      </button>
    </motion.div>
  );
};

export default CookieBar;
