// extracted by mini-css-extract-plugin
export var contactAddress = "Enquiries-module--contactAddress--319fb";
export var contactDetails = "Enquiries-module--contactDetails--b7500";
export var container = "Enquiries-module--container--2c3a1";
export var form = "Enquiries-module--form--c2ed7";
export var heading = "Enquiries-module--heading--8aa5d";
export var lead = "Enquiries-module--lead--5a316";
export var list = "Enquiries-module--list--5dfcd";
export var listItem = "Enquiries-module--listItem--2702e";
export var signpost = "Enquiries-module--signpost--26a1d";
export var signpostDesc = "Enquiries-module--signpostDesc--31b74";
export var signpostIcon = "Enquiries-module--signpostIcon--432da";
export var signpostTitle = "Enquiries-module--signpostTitle--8e4c4";