exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-search-js": () => import("./../../../src/pages/blog/search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-book-a-scan-js": () => import("./../../../src/pages/book-a-scan.js" /* webpackChunkName: "component---src-pages-book-a-scan-js" */),
  "component---src-pages-book-appointment-js": () => import("./../../../src/pages/book-appointment.js" /* webpackChunkName: "component---src-pages-book-appointment-js" */),
  "component---src-pages-book-cardiac-ct-health-check-js": () => import("./../../../src/pages/book-cardiac-ct-health-check.js" /* webpackChunkName: "component---src-pages-book-cardiac-ct-health-check-js" */),
  "component---src-pages-book-gp-health-check-js": () => import("./../../../src/pages/book-gp-health-check.js" /* webpackChunkName: "component---src-pages-book-gp-health-check-js" */),
  "component---src-pages-book-prostate-health-check-js": () => import("./../../../src/pages/book-prostate-health-check.js" /* webpackChunkName: "component---src-pages-book-prostate-health-check-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-general-enquiry-js": () => import("./../../../src/pages/general-enquiry.js" /* webpackChunkName: "component---src-pages-general-enquiry-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-search-js": () => import("./../../../src/pages/news/search.js" /* webpackChunkName: "component---src-pages-news-search-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-categories-category-js": () => import("./../../../src/templates/categories/category.js" /* webpackChunkName: "component---src-templates-categories-category-js" */),
  "component---src-templates-categories-faq-category-js": () => import("./../../../src/templates/categories/faqCategory.js" /* webpackChunkName: "component---src-templates-categories-faq-category-js" */),
  "component---src-templates-categories-news-category-js": () => import("./../../../src/templates/categories/newsCategory.js" /* webpackChunkName: "component---src-templates-categories-news-category-js" */),
  "component---src-templates-categories-news-tag-js": () => import("./../../../src/templates/categories/newsTag.js" /* webpackChunkName: "component---src-templates-categories-news-tag-js" */),
  "component---src-templates-categories-service-category-js": () => import("./../../../src/templates/categories/serviceCategory.js" /* webpackChunkName: "component---src-templates-categories-service-category-js" */),
  "component---src-templates-categories-tag-js": () => import("./../../../src/templates/categories/tag.js" /* webpackChunkName: "component---src-templates-categories-tag-js" */),
  "component---src-templates-pages-careers-js": () => import("./../../../src/templates/pages/careers.js" /* webpackChunkName: "component---src-templates-pages-careers-js" */),
  "component---src-templates-pages-consultant-directory-js": () => import("./../../../src/templates/pages/consultant-directory.js" /* webpackChunkName: "component---src-templates-pages-consultant-directory-js" */),
  "component---src-templates-pages-cqc-js": () => import("./../../../src/templates/pages/cqc.js" /* webpackChunkName: "component---src-templates-pages-cqc-js" */),
  "component---src-templates-pages-default-js": () => import("./../../../src/templates/pages/default.js" /* webpackChunkName: "component---src-templates-pages-default-js" */),
  "component---src-templates-pages-events-js": () => import("./../../../src/templates/pages/events.js" /* webpackChunkName: "component---src-templates-pages-events-js" */),
  "component---src-templates-pages-faqs-js": () => import("./../../../src/templates/pages/faqs.js" /* webpackChunkName: "component---src-templates-pages-faqs-js" */),
  "component---src-templates-pages-home-js": () => import("./../../../src/templates/pages/home.js" /* webpackChunkName: "component---src-templates-pages-home-js" */),
  "component---src-templates-pages-locations-js": () => import("./../../../src/templates/pages/locations.js" /* webpackChunkName: "component---src-templates-pages-locations-js" */),
  "component---src-templates-pages-service-local-js": () => import("./../../../src/templates/pages/service-local.js" /* webpackChunkName: "component---src-templates-pages-service-local-js" */),
  "component---src-templates-pages-services-js": () => import("./../../../src/templates/pages/services.js" /* webpackChunkName: "component---src-templates-pages-services-js" */),
  "component---src-templates-pages-team-js": () => import("./../../../src/templates/pages/team.js" /* webpackChunkName: "component---src-templates-pages-team-js" */),
  "component---src-templates-types-consultant-js": () => import("./../../../src/templates/types/consultant.js" /* webpackChunkName: "component---src-templates-types-consultant-js" */),
  "component---src-templates-types-event-js": () => import("./../../../src/templates/types/event.js" /* webpackChunkName: "component---src-templates-types-event-js" */),
  "component---src-templates-types-faq-js": () => import("./../../../src/templates/types/faq.js" /* webpackChunkName: "component---src-templates-types-faq-js" */),
  "component---src-templates-types-job-js": () => import("./../../../src/templates/types/job.js" /* webpackChunkName: "component---src-templates-types-job-js" */),
  "component---src-templates-types-location-js": () => import("./../../../src/templates/types/location.js" /* webpackChunkName: "component---src-templates-types-location-js" */),
  "component---src-templates-types-news-article-js": () => import("./../../../src/templates/types/newsArticle.js" /* webpackChunkName: "component---src-templates-types-news-article-js" */),
  "component---src-templates-types-post-js": () => import("./../../../src/templates/types/post.js" /* webpackChunkName: "component---src-templates-types-post-js" */),
  "component---src-templates-types-service-js": () => import("./../../../src/templates/types/service.js" /* webpackChunkName: "component---src-templates-types-service-js" */)
}

