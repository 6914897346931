const labels = { 
  address: 'Address',
  gender: 'Gender',
  otherGender: 'Other gender',
  firstName: "First name",
  surname: "Surname",
  dob: 'Date of Birth',
  day: 'Birth day',
  month: 'Birth month',
  year: 'Birth year',
  email: "Your email",
  telephone: "Telephone",
  nhsNum: "NHS Number",
  preferredContactMethod: "Preferred method of contact",

  name: "Name",
  preferredLanguage: "Preferred language other than English",

  gpName: "Name of your GP",
  gpAddress: "Address of your GP",
  gpEmail: "Email of your GP",
  gpTel: "Telephone of your GP",
  practiceType: "Is this an NHS or Private GP Practice?",
  practiceTypeNhs: "NHS",
  practiceTypePrivate: "Private",

  hasChestPain: 'I have experienced chest pain on exertion',
  hasBlackOutsOrLossOfConsciousness: 'I have experienced black outs or loss of consciousness',
  hasShortnessOfBreathWhenFlat: 'I have experienced shortness of breath when lying flat',
  medications: 'Please write all medications you currently take, including the dose',
  restingHeartRateAndBloodPressure: 'What is your resting heart rate and blood pressure?',
  nhsCardiacInvestigation: 'Are you currently being investigated for cardiac problems in the NHS?',
  optionsMedicalCondition: 'Do you currently have or been diagnosed with any of the following medical conditions?',
  intoleranceToBetaBlocker: 'Do you have intolerance to Beta-blocker medication (Metoprolol, Atenolol, Bisoprolol, Labetalol, Propranolol)?',
  intoleranceToGTN: 'Do you have intolerance to Glyceryl Trinitrate (GTN) medication?',
  takingVerapamil: 'Are you taking Verapamil (Securon, Cordilox, Univer, Verapress, Vertab)?',
  takingDiltiazem: 'Are you taking Diltiazem (Adizem, Angitil, Cartia, Dilcardia, Dilacor XR, Diltzac, Dilzem, Slozem, Taztia, Tiazac, Tildiem, Viazem, Zemtard)?',
  isPregnant: 'Are you or could you be pregnant?',
  
  dataConsent: "I consent for my results to be shared with my preferred GP and I consent to Medical Imaging Partnership and Prime Health processing my personal data for the purpose of my care pathway",
  confirmContact: 'In order for us to process your enquiry please confirm that we can contact you regarding your referral',
  termsAndConditions: 'I have read and agree to the privacy policy and terms of business',

  height: 'Height (in cm)',
  weight: 'Weight (in kg)',
  mobilityIssues: 'If you have mobility issues, please specify'
};

export default labels;