
import React, { useEffect, useState } from 'react';
import Step from '../Step';
import Form from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button from '../../UI/Button/Button';
import * as utilities from '../../../styles/utilities.module.scss';

const optionsInterestedIn = [
  { label: 'General Health Check', value: null },
  // { label: 'Advanced Health Check', value: null }, 
  { label: 'Perimenopause & Menopause Health Check', value: null }, 
  { label: 'Gynaecology Health Check', value: null }, 
  { label: 'Ovarian Health Check', value: null }, 
  { label: 'Bladder and Urology Health Check', value: null }, 
  // { label: 'Cardiac Health Check', value: null }, 
  // { label: 'Liver Health Check', value: null }
];

function isChecked(formData, key, value){
  const obj = formData ? formData[ key ] : null;

  if( Array.isArray(obj) ) {
    return obj.some(v => 
      v === value
    )
  } else {
    return obj === value
  }
}


const BookStep4 = ({ onSubmit, data, form, labels, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });

  function getFormData(form) {
    const obj = getFormDataAsObject(form);
    return obj;
  }

  function handleFormChange(e) {
    setFormData(getFormData(e.target.form));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = getFormData(e.target);
    onSubmit(formData);
  }

  useEffect(() => {
    setFormData({ ...data });
  }, [ data ])
  
  return (
    <Step title="GP health checks" selectedValue={formData} labels={labels} {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        <p>These packages are based on a specific clinical indicators. Our GPs may discuss with you based on your initial consultation further tests or differing blood tests where clinically indicated. This will be discussed with you as a patient to ensure you are well informed and consent to the changes based on what you discuss with the GP.</p>

        <Form.Row className={utilities.mb40}>
          <Form.CheckboxList 
            label={labels.optionsInterestedIn}
            name="optionsInterestedIn"
            options={optionsInterestedIn.map(({ label, value }) => {
              const val = value || label;
              return { 
                label, 
                value: val,
                checked: isChecked(formData, 'optionsInterestedIn', val)
              }
            })} 
            />
        </Form.Row>
        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default BookStep4;