const labels = {
  patientGender: "Gender",
  patientFirstName: "First name",
  patientSurname: "Surname",
  patientAddress: "Address",
  patientPostcode: "Postcode",
  patientEmail: "Email",
  patientTelephone: "Telephone",
  patientDob: "Date of birth",

  funding: "Funding",
  insurer: "Insurer",
  policyNumber: "Policy no.",
  preauthNumber: "Pre-auth no.",
  
  centre: "Location",
  preferredDate: "Preferred scan date",
  preferredTimeslots: "Preferred time slot(s)",
  scanType: "Type of imaging required",
  contraindications: "Contraindications",
  kidneyHistory: "History of kidney disease/surgery",
  dialysisHistory: "History of dialysis",
  isSixtyFivePlus: "65yrs or over",
  serumCreatinine: "Serum creatinine / eGFR",
  dateMeasured: "Date measured",

  bodyPart: "Body part",

  indication: "Clinical indication",
  hasPreviousScan: "Previous scan",
  previousScanReport: "Previous report",
  previousScanWhen: "When?",
  previousScanWhere: "Where?",

  referrerName: "Your name",
  referrerProfession: "Your profession",
  referrerType: 'Are you',
  referrerEmail: "Email",
  referrerTelephone: "Telephone",
  cmcHpcpNumber: "GMC, GDC or HCPC Number",

  gender: "Gender",
  firstName: "First name",
  dob: "Date of birth",
  surname: "Surname",
  email: "Email",
  telephone: "Telephone",
  comments: "Comments"
};

export default labels;