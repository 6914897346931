import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useContactFormData } from './useContactFormData';
import labels from './GpHealthCheck/labels';
import Alert, { alertLevel } from '../UI/Alert/Alert';
import convertObjectToFormData from '../../utils/convert-object-to-formdata';
import * as styles from './Enquiries.module.scss';
import STEPS from './GpHealthCheck/GpHealthCheckSteps';

const GpHealthCheck = ({ name = "gp-health-check", showTitle = true, pageContext, ...other }) => {
  const [ formName, setFormName ] = useState(name);
  const [ steps, setSteps ] = useState(cloneDeep(STEPS));
  const [ status, setStatus ] = useState(false);
  const {
    wp: {
      themeGeneralSettings: {
        siteOptions: { tel },
      },
    }
  } = useContactFormData();

  function onStepSubmit(formData, payload, stepIndex) {
    setSteps(state => {
      const newState = [ ...state ];

      newState[ stepIndex ] = {
        ...newState[ stepIndex ],
        formData
      };

      newState.forEach((step, index) => {
        step.isOpen = index === stepIndex + 1;

        if( index > stepIndex ) {
          step.formData = null;
        }

        if( index === stepIndex + 1 ) {
          step.isDisabled = false;
          step.isOpen = true;
        }
      });

      return newState;
    });

    if(payload?.formId) {
      setFormName(payload.formId);
    }
  }

  function onStepClick(stepIndex) {
    setSteps(state => {
      if(!state[ stepIndex ]) return state;

      const newState = [ ...state ];
      let disableSteps = true;

      newState[ stepIndex ].isOpen = !newState[ stepIndex ].isOpen;

      if( !newState[ stepIndex ].isOpen ) {
        disableSteps = false;
      }

      newState.forEach((step, index) => {
        if( index > stepIndex ) {
          step.isOpen = !disableSteps;
          step.isDisabled = disableSteps;
        }
      });

      return newState;
    })
  }

  useEffect(() => {
    const allFormData = steps.map(step => step.formData).filter(formData => formData);

    function submit(){
      let payload = {
        "form-name": `book-${formName}`,
        "bot-field": ``,
        "subject": `Prime Health GP Health Check Web Referral`
      };

      steps.forEach(step => {
        payload = { ...payload, ...step.formData };
      })

      const options = {
        method: 'POST',
        body: convertObjectToFormData(payload)
      }

      fetch("", options)
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            console.error(
              `Looks like there was a problem. Status Code: ${response.status}`
            );
            
            setStatus({ isSubmitted: true, isSuccessful: false });

            return;
          }

          setStatus({ isSubmitted: true, isSuccessful: true });

          window.dataLayer.push({
            'event': 'Form Submission',
            'formType': 'Book GP health check'
          });
        })
        .catch(function (err) {
          console.error('Fetch Error :-S', err);
        });
    }

    if(steps.length && allFormData.length === steps.length) {
      submit();
    }
  }, [ steps, formName ]);

  return (
    <div {...other}>
      {showTitle &&
        <h2 className={styles.heading}>
          <em>Book</em> a <em>GP health check</em> at <em>Prime Health</em>
        </h2>
      }

      <p className={styles.lead}>
        If you would like to book an appointment for a GP Health Check at one of our centres, please complete the form below, or you can call us on{' '}
        <a href={`tel:+44${`03300250150`.replace(/ /g, '')}`}>
          <strong>{`03300 250150`}</strong>
        </a>
      </p>

      {!status.isSubmitted && 
        <>
          {steps.map( ({ step: Step, isOpen, isDisabled, formData }, index) => {
            return (
              <Step 
                onSubmit={(formData, payload) => onStepSubmit(formData, payload, index)} 
                onClick={index => onStepClick(index)}
                data={formData}
                labels={labels}
                form={steps}
                isDisabled={isDisabled}
                index={index}
                number={index + 1}
                isOpen={isOpen} 
                key={index}/>
            )
          })}
        </>
      }

      {status.isSubmitted && (
          <Alert level={status.isSuccessful ? alertLevel.SUCCESS : alertLevel.DANGER}>
            {status.isSuccessful ? `Thank you for your enquiry. One of our dedicated Care Coordinators will be in touch to discuss your GP health check.` : `Looks like there was a problem, please try again later.`}
          </Alert>
      )}
    </div>
  );
};

export default GpHealthCheck;