import React, { useMemo, useState } from 'react';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button from '../../../UI/Button/Button';
import { subYears } from 'date-fns';
import labels from '../labels';
import { graphql, useStaticQuery } from 'gatsby';
import Alert, { alertLevel } from '../../../UI/Alert/Alert';
import useFormData from '../../../../hooks/useFormData';

const ctQuestions = [
  {
    name: "kidneyHistory",
    label: "Is there a history of kidney disease/surgery?"
  },
  {
    name: "dialysisHistory",
    label: "Is there a history of dialysis"
  },
  {
    name: "isSixtyFivePlus",
    label: "Is the patient aged 65yrs or over?"
  }
];

const ClinicianFormStep4 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useFormData({ ...data });
  const [ preferredDate, setPreferredDate ] = useState(null);
  const [ dateMeasured, setDateMeasured ] = useState(null);
  const {
    allWpLocation: {
      nodes: locations
    },
    wp: { 
      acfOptionsRequestAScan: { 
        settingsRequestAScan: {
          scanTypesClinician,
          contraindications
        } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query ClinicianFormStep3Query {
        allWpLocation {
          nodes {
            id
            title
            locationDetails {
              timeSlots {
                label
                timeSlot {
                  from
                  until
                }
              }
            }
          }
        }
        wp {
          acfOptionsRequestAScan {
            settingsRequestAScan {
              scanTypesClinician {
                locations {
                  ... on WpLocation {
                    id
                    title
                  }
                }
                type
              }
              contraindications {
                value
              }
            }
          }
        }
      }
    `
  );

  const selectedLocation = formData?.centre;
  const referrerType = useMemo(() => {
    return form.find(({ formData }) => 
      formData?.referrerType)?.formData?.referrerType;
  },[ form ]);

  const scanTypes = scanTypesClinician.filter(scanType => {
    if(scanType.type.trim() === `CT` && referrerType === `Allied Health Professional` ) {
      return false;
    }

    return scanType.locations.some(({ title }) => 
      title === selectedLocation
    );
  });

  const times = useMemo(() => {
    const match = locations.find(({ title }) => {
      return title === selectedLocation;
    });
    return match?.locationDetails.timeSlots || [];
  }, [ selectedLocation, locations ]);

  const showSerumCreatinine = useMemo(() => {
    return ctQuestions.some(({ name }) => {
      return formData[ name ] && formData[ name ] === 'Yes';
    });
  },[ formData ]);

  function isChecked(value){
    if( Array.isArray( formData?.preferredTimeslots ) ) {
      return formData?.preferredTimeslots?.some(timeslot => 
        timeslot === value
      )
    } else {
      return formData?.preferredTimeslots === value
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }
  
  return (
    <Step title="Appointment" selectedValue={data} {...other}>
      <Form onSubmit={handleSubmit} onChange={e => setFormData(e.target.form)}>
        <Form.Fieldset label="Location">
          {locations.map((location, index) => (
              <Form.Radio 
                label={location.title} 
                name="centre" 
                id={`centre${index}`} 
                value={location.title} 
                required 
                checked={formData?.centre === location.title}
                key={index}
              />
          ))}
        </Form.Fieldset>

        {selectedLocation &&
          <>
            <Form.Row>
              <Form.DatePicker 
                theme={fieldThemes.PRIMARY_FILLED} 
                label={labels.preferredDate}
                name="preferredDate" 
                id="preferredDate" 
                selected={preferredDate} 
                onChange={(date) => setPreferredDate(date)}
                minDate={new Date()}
                dateFormat="d MMMM yyyy"
                required/>
            </Form.Row>

            <Form.Fieldset label="Select preferred time slot">
              {
                times.map((group, index) => {
                  return group.timeSlot ? (
                    <Form.CheckboxList 
                      label={group.label}
                      name="preferredTimeslots"
                      listId={index}
                      options={group.timeSlot.map(({ from, until }) => {
                        const label = `${from} - ${until}`;
                        const value = `${group.label}: ${label}`;
                        
                        return { 
                          label, 
                          value,
                          checked: isChecked(value)
                        }
                      })} 
                      key={index}
                      noValidate
                      required={!formData.preferredTimeslots}
                      />
                  ) : null
                })
              }
            </Form.Fieldset>

            <Form.Fieldset label="Type of imaging required">
              {scanTypes.map(({ type }, index) => (
                  <Form.Radio 
                    label={type} 
                    name="scanType" 
                    id={`scanType${index}`} 
                    value={type} 
                    required 
                    defaultChecked={formData?.scanType === type}
                    key={index}
                  />
              ))}
            </Form.Fieldset>
          </>
        }

        {formData?.scanType?.trim() === 'MRI' && (
          <>
            <p>Does the patient have any of the following possible contraindications to 3T MRI (tick if relevant)</p>
            
            <Form.Row>
              {contraindications.map(({ value }, index) => (
                  <Form.Checkbox 
                    label={value} 
                    name="contraindications" 
                    id={`contraindications${index}`} 
                    value={value} 
                    checked={
                      Array.isArray(formData?.contraindications) ? 
                        formData.contraindications?.some(val => val === value ) : 
                        formData?.contraindications === value
                    }
                    key={index}
                  />
              ))}
            </Form.Row>
            
            { formData?.contraindications &&
              <Alert level={alertLevel.WARNING}>
                There may be an issue scanning this patient. Please call the centre you are referring to discuss your options
              </Alert>
            }
          </>
        )}

        {formData?.scanType?.trim() === 'CT' && (
          <>
            <p>IMPORTANT: If the answer is YES to the following, Serum Creatinine levels must be provided:</p>

            {ctQuestions.map((question, index) => (
              <Form.Fieldset label={question.label} key={index}>
                <Form.Radio label="Yes" name={question.name} id={`${question.name}No`} value="Yes" checked={formData[ question.name ] === 'Yes'} required />
                <Form.Radio label="No" name={question.name} id={`${question.name}Yes`} value="No" checked={formData[ question.name ] === 'No'} required />
              </Form.Fieldset>
            ))}

            {showSerumCreatinine &&
              <>
               <Form.Row>
                  <Form.Input theme={fieldThemes.PRIMARY_FILLED} label="Serum creatinine / eGFR" name="serumCreatinine" id="serumCreatinine" value={formData.serumCreatinine} required/>
                </Form.Row>

                <Form.Row>
                  <Form.DatePicker 
                    theme={fieldThemes.PRIMARY_FILLED} 
                    label={labels.dateMeasured}
                    name="dateMeasured" 
                    id="dateMeasured" 
                    selected={dateMeasured} 
                    onChange={(date) => setDateMeasured(date)}
                    minDate={subYears(new Date(), 5)}
                    maxDate={new Date()}
                    dateFormat="d MMMM yyyy"
                    required
                    />
                </Form.Row>
              </>
            }           
          </>
        )}

        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default ClinicianFormStep4;