import React from 'react';

const HtmlContent = React.forwardRef(
  ({ as: Comp = 'div', content, ...other }, ref) => {
    return (
      <Comp
        {...other}
        dangerouslySetInnerHTML={{ __html: content }}
        ref={ref}
      />
    );
  }
);

export default HtmlContent;
