import React, { useMemo } from 'react';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button, { themes as ButtonThemes } from '../../../UI/Button/Button';
import labels from '../labels';
import useFormData from '../../../../hooks/useFormData';

const ClinicianFormStep6 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useFormData(data);
  const showIncludeReport = useMemo(() => formData?.hasPreviousScan === 'Yes', [ formData ]);

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  return (
    <Step title="Clinical indication for examination" selectedValue={data} {...other}>
      <p>Please summarise relevant history, clinical findings and test results. Indicate the question that the examination should answer.</p>

      <Form onSubmit={handleSubmit} onChange={e => setFormData(e.target.form)}>
        <Form.Row>
          <Form.TextArea 
            label={labels.indication}
            name="indication" 
            id="indication" 
            rows="4" 
            theme={fieldThemes.PRIMARY_FILLED} 
            value={formData?.indication} 
            required/>
        </Form.Row>

        <Form.Fieldset label="Has the patient had a previous scan?">
          <Form.Row>
            <Form.Radio label="Yes - please include previous report" name="hasPreviousScan" id="previousScan1" value="Yes" checked={formData.hasPreviousScan === 'Yes'}/>
            <Form.Radio label="No" name="hasPreviousScan" id="previousScan2" value="No" checked={formData.hasPreviousScan === 'No'}/> 
          </Form.Row>

          {showIncludeReport &&
            <>
              <Form.Row>
                <Form.File label="Attach previous report (PDF format only)" id="previousScanReport" name="previousScanReport" type="file" accept="application/pdf" required/>
              </Form.Row>

              <Form.Row>
                <Form.TextArea 
                  label={labels.previousScanWhen}
                  name="previousScanWhen" 
                  id="previousScanWhen" 
                  rows="4" 
                  theme={fieldThemes.PRIMARY_FILLED} 
                  value={formData?.previousScanWhen} 
                  required/>
              </Form.Row>

              <Form.Row>
                <Form.TextArea 
                  label={labels.previousScanWhere}
                  name="previousScanWhere" 
                  id="previousScanWhere" 
                  rows="4" 
                  theme={fieldThemes.PRIMARY_FILLED} 
                  value={formData?.previousScanWhere} 
                  required/>
              </Form.Row>
            </>
          }
        </Form.Fieldset>

        <Form.Row>
          <Form.Checkbox label="I am happy to occasionally be informed you of new services, exclusive price offers and educational events." name="marketing" id="marketing"/>
          <Form.Checkbox label={<>I agree to the <a href="/privacy-policy/" target='_blank'>privacy policy</a>.</>} name="privacyPolicy" id="privacyPolicy" required/>
        </Form.Row>

         <Button theme={ButtonThemes.PRIMARY} type="submit">Request your scan</Button>
      </Form>
    </Step>
  )
};


export default ClinicianFormStep6;