import React, { useContext } from 'react';
import AppContext from '../../context/AppContext';
import Enquiries from '../Enquiries/Enquiries';
import Link from '../UI/Link/Link';
import * as styles from './NavSecondary.module.scss';

const NavSecondary = ({ nodes }) => {
  const { dispatch } = useContext(AppContext);

  return (
    <ul className={styles.menu}>
      <li>
        <button
          onClick={(e) =>
            dispatch({
              type: 'MODAL_SHOW',
              modal: { children: <Enquiries.Contact /> },
            })
          }
        >
          Contact
        </button>
      </li>
     
      {nodes.map((node) => {
        const path = node?.connectedNode?.node?.uri ?? node.url;

        return (
          <li key={node.id}>
            <Link to={path}>
              {node.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NavSecondary;
