import React, { useContext } from 'react';
import AppContext from '../../context/AppContext';
import Contact from './Contact';
import Referrals from './Referrals';
import Book from './Book';
import ProstateScreen from './ProstateScreen';
import GpHealthCheck from './GpHealthCheck';
import RequestScan from './RequestScan/RequestScan';
import CardiacScan from './CardiacScan';
import Signpost from './Signpost';
import { useContactFormData } from './useContactFormData';
import * as styles from './Enquiries.module.scss';

const Enquiries = ({ pageContext, ...other }) => {
  const {
    wp: {
      themeGeneralSettings: { siteOptions },
    },
  } = useContactFormData();
  const { dispatch } = useContext(AppContext);

  return (
    <div {...other}>
      <h2 className={styles.heading}>
        <em>Make</em> an <em>enquiry</em>
      </h2>
      <p className={styles.lead}>
        Our team are here to help. Please select one of the options below or call on{' '}
        <a href={`tel:+44${siteOptions.tel.replace(/ /g, '')}`}>
          <strong>{siteOptions.tel}</strong>
        </a>
        .
      </p>

      <Signpost
        title={`Self Referral MRI Scans`}
        desc={`Register and book an MRI scan. No referral required.`}
        to={`https://www.myhealthreferral.co.uk/register`}
      />

      <Signpost
        title={`Book a scan`}
        desc={`Patients and healthcare professionals can use our secure online form to book a scan.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.RequestScan pageContext={pageContext} />,
            },
          })
        }
      />

      <Signpost
        title={`Book an outpatient or GP appointment`}
        desc={`Where possible we offer appointments at times convenient to you. Complete our booking form, and we will be in touch to confirm your appointment.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.Book pageContext={pageContext} />,
            },
          })
        }
      />

      <Signpost
        title={`Book a prostate health check`}
        desc={`Patients can use our secure online form to book a prostate health check.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.ProstateScreen pageContext={pageContext} />,
            },
          })
        }
      />

      <Signpost
        title={`Book a cardiac CT health check`}
        desc={`Patients can use our secure online form to book a cardiac CT health check.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.CardiacScan pageContext={pageContext} />,
            },
          })
        }
        />

      <Signpost
        title={`Book a GP health check`}
        desc={`Patients can use our secure online form to book a GP health check.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.GpHealthCheck pageContext={pageContext} />,
            },
          })
        }
      />

      <Signpost
        title={`Book a CBCT scan`}
        desc={`Patients and Dental Professionals can use our secure online form to book a CBCT scan.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.RequestScan pageContext={pageContext} />,
            },
          })
        }
      />

      <Signpost
        title={`General enquiry`}
        desc={`If you have a general question about our services, complete our enquiry form.`}
        onClick={(e) =>
          dispatch({
            type: 'MODAL_SHOW',
            modal: {
              children: <Enquiries.Contact pageContext={pageContext} />,
            },
          })
        }
      />
    </div>
  );
};

Enquiries.Contact = Contact;
Enquiries.Referrals = Referrals;
Enquiries.Book = Book;
Enquiries.RequestScan = RequestScan;
Enquiries.ProstateScreen = ProstateScreen;
Enquiries.CardiacScan = CardiacScan;
Enquiries.GpHealthCheck = GpHealthCheck;

export default Enquiries;
