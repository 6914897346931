import React from 'react';
import Step from '../../Step';
import Form from '../../../UI/Form/Form';
import { graphql, useStaticQuery } from 'gatsby';

const PatientFormStep2 = ({ onSubmit, data, form, ...other }) => {
  const { 
    wp: { 
      acfOptionsRequestAScan: { 
        settingsRequestAScan: { 
          fundingOptionsPatient 
        } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query PatientFormStep2Query {
        wp {
          acfOptionsRequestAScan {
            settingsRequestAScan {
              fundingOptionsPatient {
                value
              }
            }
          }
        }
      }
    `
  );

  return (
    <Step title="How will your scan be funded?" selectedValue={data?.funding} {...other}>
      {fundingOptionsPatient.map(({value}, index) => (
          <Form.Radio 
            label={value} 
            name="funding" 
            id={`funding${index}`} 
            value={value} 
            required 
            checked={data?.funding === value}
            onChange={e => onSubmit({ [ e.target.name ]: e.target.value }) }
            onClick={e => onSubmit({ [ e.target.name ]: e.target.value }) }
            key={index}
          />
      ))}
    </Step>
  )
};


export default PatientFormStep2;