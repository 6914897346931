import React, { useState } from 'react';
import Step from '../../Step';
import Form from '../../../UI/Form/Form';
import Alert from '../../../UI/Alert/Alert';
import { graphql, useStaticQuery } from 'gatsby';
import HtmlContent from '../../../UI/HtmlContent/HtmlContent';

const PatientFormStep1 = ({ onSubmit, data, form, ...other }) => {
  const [ showAlert, setShowAlert ] = useState(false);
  const { 
    wp: { 
      acfOptionsRequestAScan: { 
        settingsRequestAScan: { 
          noReferralMessage 
        } 
      } 
    }
  } = useStaticQuery(
    graphql`
      query PatientFormStep1Query {
        wp {
          acfOptionsRequestAScan {
            settingsRequestAScan {
              noReferralMessage
            }
          }
        }
      }
    `
  );

  function handleYes(e){
    onSubmit({ [ e.target.name ]: e.target.value });
    setShowAlert(false);
  }

  return (
    <Step title="Do you have a referral?" selectedValue={data?.hasReferral} {...other}>
      <Form.Row>
        <Form.Radio label="Yes" name="hasReferral" id="hasReferral1" value="Yes" onClick={handleYes} required/>
        <Form.Radio label="No" name="hasReferral" id="hasReferral2" value="No" onClick={e => setShowAlert(true) } required/>
      </Form.Row>

      {showAlert &&
        <Alert>
          <HtmlContent content={noReferralMessage}/>
        </Alert>
      }
    </Step>
  )
};

export default PatientFormStep1;