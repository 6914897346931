import { Link as GatsbyLink } from 'gatsby';
import React, { forwardRef } from 'react';
import { useReducedMotion } from 'framer-motion';

import { getUrlPath } from '../../../utils/get-url-path';
import { isInternalLink } from '../../../utils/is-internal-link';

const Link = forwardRef(({ to, icon, ...other }, ref) => {
  const shouldReduceMotion = useReducedMotion();
  const path = getUrlPath(to);
  const isInternal = isInternalLink(path);

  function handleClick(path, e) {
    if( !path?.startsWith('#') && !shouldReduceMotion ) {
      window.__navigatingToLink = true;
    }
  }

  /* eslint-disable jsx-a11y/anchor-has-content */

  return isInternal ? (
    <GatsbyLink
      {...other}
      to={path}
      ref={ref}
      onClick={handleClick.bind(null, path)}
    />
  ) : (
    <a {...other} href={path} ref={ref} target="_blank" rel="noreferrer" />
  );

  /* eslint-enable jsx-a11y/anchor-has-content */
});

export default Link;
