import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import sprite from '../../../assets/icons.svg';
import * as styles from './Icon.module.scss';

const Icon = (props) => (
  <svg
    className={classnames(styles.icon, props.className)}
    style={props.style}
    aria-hidden
  >
    <use xlinkHref={`${sprite}#${props.name}`}></use>
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default Icon;
