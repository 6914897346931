import React from 'react';

const DEFAULTS = {
  state: {},
  dispatch: () => {},
};

const AppContext = React.createContext(DEFAULTS);

export default AppContext;
