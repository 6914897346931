import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import CqcLogo from '../../assets/images/cqc-logo-wo.inline.svg';
import useResponsive from '../../hooks/useResponsive';
import flatListToHierarchical from '../../utils/flatListToHierarchical';
import NewsletterSignup from '../NewsletterSignup/NewsletterSignup';
import Columns from '../UI/Columns/Columns';
import {
  CustomAccordion,
  CustomAccordionItem,
  CustomAccordionItemHeading,
  CustomAccordionItemButton,
  CustomAccordionItemPanel,
} from '../UI/CustomAccordion/CustomAccordion';
import Link from '../UI/Link/Link';
import NavList from '../UI/NavList/NavList';
import Ripple from '../UI/Ripple/Ripple';
import { SocialLinks } from '../UI/Social/Social';
import * as styles from './Footer.module.scss';

const FOOTER_QUERY = graphql`
  query FooterMenuQuery {
    menu: wpMenu(locations: {eq: FOOTER}) {
      ...MenuItems
    }

    wp {
      themeGeneralSettings {
        siteOptions {
          address
          email
          fax
          tel
        }
      }
    }

    cqcPage: wpPage(databaseId: {eq: 5516}) {
      uri
    }
  }
`;

const Footer = () => {
  const {
    menu,
    wp: { themeGeneralSettings: { siteOptions } },
    cqcPage
  } = useStaticQuery(FOOTER_QUERY);
  const nodes = menu && flatListToHierarchical(menu.menuItems.nodes);
  const { isLarge } = useResponsive();

  return (
    <footer className={styles.footer} id="footer">
      <div className={styles.topRow}>
        <NewsletterSignup className={styles.newsletter} />

        <div className={styles.navigation}>
          {nodes?.length > 0 && (
            <>
              {isLarge ? (
                <Columns count={4} showBorders={false}>
                  {nodes.map((node) => (
                    <React.Fragment key={'test1' + node.id}>
                      <h2 className={styles.sectionTitle}>{node.label}</h2>
                      <NavList isReverse nodes={node.children} />
                    </React.Fragment>
                  ))}
                </Columns>
              ) : (
                <CustomAccordion isBordered isReverse collapsible>
                  {nodes.map((node) => (
                    <CustomAccordionItem uuid={node.id} key={'test2' + node.id}>
                      <CustomAccordionItemHeading>
                        <CustomAccordionItemButton>
                          {node.label}
                        </CustomAccordionItemButton>
                      </CustomAccordionItemHeading>
                      <CustomAccordionItemPanel>
                        <NavList isReverse isPrimary nodes={node.children} />
                      </CustomAccordionItemPanel>
                    </CustomAccordionItem>
                  ))}
                </CustomAccordion>
              )}
            </>
          )}
        </div>

        <div className={styles.contact}>
          <SocialLinks isReverse />

          <p className={styles.contactDetails}>
            <strong>
              Tel:{' '}
              <a href={`tel:+${siteOptions.tel.replace(/ /g, '')}`}>
                {siteOptions.tel}
              </a>
              <br />
              {siteOptions.fax && (
                <>
                  Fax: {siteOptions.fax}
                  <br />
                </>
              )}
              Email:{' '}
              <a href={`mailto:${siteOptions.email}`}>{siteOptions.email}</a>
            </strong>
          </p>

          <address className={styles.contactAddress}>
            <strong>Prime Health</strong>
            <br />
            {siteOptions.address}
          </address>

          <div className={styles.cqc}>
            Regulated by
            <Link to={cqcPage.uri}>
              <CqcLogo alt="Care Quality Commission" />
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.bottomRow}>
        <p>Prime Health Surrey: Prime Health Diagnostics Ltd | Registered Company No: 07199371 | England.</p>
        <p>Prime Health Manchester and Prime Health Harley Street are part of Medical Imaging Partnership Ltd | Registered Company No: 06713311 | England</p>
        <small>
          Copyright Prime Health {new Date().getFullYear()}, all rights reserved.
        </small>
      </div>

      <div className={styles.watermark}>
        <Ripple />
      </div>
    </footer>
  );
};

export default Footer;
