import classNames from 'classnames';
import React from 'react';

import * as styles from './ButtonGroup.module.scss';

const ButtonGroup = ({ children, isBlock }) => {
  return (
    <div
      className={classNames(styles.buttonGroup, { [ styles.isBlock ]: isBlock })}
    >
      {React.Children.map(
        children,
        (child) =>
          child && (
            <div className={styles.item} key={child.id}>
              {React.cloneElement(child, {
                isBlock,
              })}
            </div>
          )
      )}
    </div>
  );
};

export default ButtonGroup;
