const labels = { 
  address: 'Address',
  gender: 'Gender',
  otherGender: 'Other gender',
  firstName: "First name",
  surname: "Surname",
  dob: 'Date of Birth',
  day: 'Birth day',
  month: 'Birth month',
  year: 'Birth year',
  email: "Your email",
  telephone: "Telephone",
  nhsNum: "NHS Number",
  preferredContactMethod: "Preferred method of contact",

  gpName: "Name of your GP",
  gpAddress: "Address of your GP",
  gpEmail: "Email of your GP",
  gpTel: "Telephone of your GP",
  practiceType: "Is this an NHS or Private GP Practice?",
  practiceTypeNhs: "NHS",
  practiceTypePrivate: "Private",

  confirmNoInfection: 'I can confirm I have not been treated for a urinary tract infection or prostate infection in last 6 weeks',
  dataConsent: "I consent for my results to be shared with my preferred GP and I consent to Medical Imaging Partnership and Prime Health processing my personal data for the purpose of my care pathway",
  prostateScreenAware: 'I am aware this is a prostate health check service and the results may reveal there is a risk of cancer being present, and recommend further treatment which may include prostate biopsies',
  confirmContact: 'In order for us to process your enquiry please confirm that we can contact you regarding your referral',
  termsAndConditions: 'I have read and agree to the privacy policy and terms of business',

  implants: 'Implants',

  referralType: 'Referral type',
  referralTitle: 'Are you referring yourself or do you have a GP Referral letter? ',
  referralGP: 'I have a GP Referral',
  referralSelf: 'I am self referring with a PSA Result and will upload a copy of my PSA result',
  referralFileInfo: 'You will be require to upload a PDF/JPEG version of your PSA Test Result OR GP Referral to complete this booking request.',

  referralLetter: 'Please attach your referral letter (PDF or JPEG format only)',
  ethnicity: 'Please indicate your ethnicity',
  otherEthnicity: 'Other ethnicity',
  optionsSelfReferral: 'About you',
  psaTestResultStatus: 'My PSA test result is',
  psaTestResults: 'Upload your PSA test results (PDF or JPEG format only)'
};

export default labels;