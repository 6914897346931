import classNames from 'classnames';
import React from 'react';

import Button, { themes as buttonThemes, sizes } from '../UI/Button/Button';
import Form, { themes as fieldThemes } from '../UI/Form/Form';
import { Grid, GridItem } from '../UI/Grid/Grid';
import * as styles from './NewsletterSignup.module.scss';

const NewsletterSignup = ({ className }) => {
  const classnames = classNames(styles.newsletterSignup, className);

  async function handleSubmit(e, setStatus) {
    const data = {};

    new FormData(e.target).forEach((value, key) => {
      data[ key ] = value;
    });

    if( !data.email ) return;

    const response = await fetch('/.netlify/functions/newsletter-signup', {
        method: 'post',
        body: JSON.stringify({
          email: data.email
        })
      })
    
    const json = await response.json();

    if (response.status !== 200 && response.status !== 201) {
      console.error(
        `Looks like there was a problem. Status Code: ${response.status}.`,
        json.detail
      );

      setStatus({ isSubmitted: true, isSuccessful: false });

      return;
    }

    setStatus({ isSubmitted: true, isSuccessful: true });

    window.dataLayer.push({
      'event': 'Form Submission',
      'formType': 'Newsletter signup'
    });
  }

  return (
    <aside className={classnames}>
      <h2 className={styles.headingSecondary}>The Prime Health Newsletter</h2>
      <h3 className={styles.headingPrimary}>
        Get our latest articles and promotions straight to your inbox.
      </h3>
      <Form
        className={styles.form}
        onSubmit={handleSubmit}
        successMessage="Many thanks for subscribing to the Prime Health Newsletter. We look forward to sharing the latest articles, promotions and service information with you."
      >
        <Grid justify="center">
          <GridItem md={6} lg={4}>
            <Form.Input
              theme={fieldThemes.SECONDARY}
              label="Email address"
              placeholder="your.email@example.com"
              name="email"
              id="email"
              isReverse
              required
            />
          </GridItem>
          <GridItem md={4} lg={2}>
            <Button
              type="submit"
              isReverse
              isBlock
              theme={buttonThemes.PRIMARY}
              size={sizes.LARGE}
            >
              Subscribe
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </aside>
  );
};

export default NewsletterSignup;
