import React from 'react';
import Step from '../Step';
import Form from '../../UI/Form/Form';
import { useContactFormData } from '../useContactFormData';

const BookStep1 = ({ onSubmit, data, form, ...other }) => {
  const { 
    allLocations: {
      nodes
    },
  } = useContactFormData();
  
  function handleChange(e) {
    onSubmit({ [ e.target.name ]: e.target.value }, { ...e.target.dataset });
  }
  
  return (
    <Step title="At which Prime Health centre would you like your appointment?" selectedValue={data?.centre} {...other}>
      {nodes.map((location, index) => (
          <Form.Radio 
            label={location.label} 
            name="centre" 
            id={`centre${index}`} 
            value={location.value} 
            required 
            checked={data?.centre === location.value}
            onChange={handleChange}
            onClick={handleChange}
            data-form-id={location.locationDetails.formId}
            key={index}
          />
      ))}
    </Step>
  )
};


export default BookStep1;