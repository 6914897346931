import React from 'react';
import SmoothScroll from 'smooth-scroll';
import vhCheck from 'vh-check';

import Layout from './src/components/Layout';
import AppProvider from './src/context/AppProvider';
import './src/polyfills';

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);

export const wrapPageElement = ({ element, props }, pluginOptions) => (
  <Layout {...props}>{element}</Layout>
);

export const shouldUpdateScroll = () => !window.__navigatingToLink;

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if( !prevLocation || prevLocation.pathname === location.pathname ) return;

  const pageHeadings = document.querySelectorAll(`#gatsby-focus-wrapper h1`);

  // Hacky workaround for gatsby announcer not using the new page heading during page transition.
  // We replace the outgoing page h1 so it's skipped by the announcer logic.
  if (pageHeadings && pageHeadings.length) {
    const heading = pageHeadings[ 0 ];
    const headingAsDiv = document.createElement('div');

    for (const attr of heading.attributes) {
      headingAsDiv.setAttribute(attr.name, attr.value);
    }

    headingAsDiv.innerHTML = heading.innerHTML;
    heading.parentNode.replaceChild(headingAsDiv, heading);
  }
}

export const onClientEntry = async () => {
  window.dataLayer = window.dataLayer || [];
  
  vhCheck({
    cssVarName: 'vh',
    force: false,
    bind: true,
    redefineVh: true,
    updateOnTouch: false,
  });

  if (typeof window !== 'undefined') {
    new SmoothScroll('a[href*="#"]');
  }
};
