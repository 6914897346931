import ClinicianFormStep1 from "./ClinicianFormStep1";
import ClinicianFormStep2 from "./ClinicianFormStep2";
import ClinicianFormStep3 from "./ClinicianFormStep3";
import ClinicianFormStep4 from "./ClinicianFormStep4";
import ClinicianFormStep5 from "./ClinicianFormStep5";
import ClinicianFormStep6 from "./ClinicianFormStep6";

const ClinicianForm = [
  {
    id: 'step-1',
    step: ClinicianFormStep1, 
    formData: null, 
    isOpen: true,
    isDisabled: false
  },
  {
    id: 'step-2',
    step: ClinicianFormStep2, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-3',
    step: ClinicianFormStep3, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-4',
    step: ClinicianFormStep4, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-5',
    step: ClinicianFormStep5, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-6',
    step: ClinicianFormStep6, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  }
];

export default ClinicianForm;