import React from 'react';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button from '../../../UI/Button/Button';
import labels from '../labels';
import useFormData from '../../../../hooks/useFormData';

const ClinicianFormStep5 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useFormData({ ...data });

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  return (
    <Step title="Area to be imaged" selectedValue={data} {...other}>
      <Form onSubmit={handleSubmit} onChange={e => setFormData(e.target.form)}>
        <Form.Row>
          <Form.TextArea 
            label={labels.bodyPart}
            placeholder="eg. left knee" 
            name="bodyPart" 
            id="bodyPart" 
            rows="4" 
            theme={fieldThemes.PRIMARY_FILLED} 
            value={formData?.bodyPart} 
            required/>
        </Form.Row>
        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};


export default ClinicianFormStep5;