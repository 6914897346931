import { useStaticQuery, graphql } from 'gatsby';

export const useContactFormData = () => {
  const query = useStaticQuery(
    graphql`
      query ContactFormQuery {
        wp {
          themeGeneralSettings {
            siteOptions {
              tel
              fax
              email
              address
            }
          }
        }

        serviceCategories: allWpServiceCategory {
          nodes {
            id
            label: name
            children: services {
              nodes {
                label: title
                value: title
              }
            }
          }
        }

        allLocations: allWpLocation {
          nodes {
            id
            label: title
            value: title
            locationDetails {
              address
              telephone
              fax
              email
              formId
            }
          }
        }
      }
    `
  );

  return query;
};
