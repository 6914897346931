import classnames from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';

import Icon from '../Icon/Icon';
import * as styles from './Alert.module.scss';

export const alertLevel = {
  INFO: {
    name: 'info',
    icon: 'info',
  },
  WARNING: {
    name: 'warning',
    icon: 'alert-circle',
  },
  DANGER: {
    name: 'danger',
    icon: 'alert-triangle',
  },
  SUCCESS: {
    name: 'success',
    icon: 'check-square',
  },
};

const Alert = ({ className, children, level = alertLevel.INFO }) => {
  const cssClass = classnames(styles.container, className, {
    [ styles[ `theme${capitalize(level.name)}` ] ]: !!level,
  });

  return (
    <div className={cssClass} role="alert">
      <Icon className={styles.icon} name={level.icon} />
      {children}
    </div>
  );
};

export default Alert;
