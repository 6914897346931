
import React, { useContext } from 'react';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button from '../../../UI/Button/Button';
import labels from '../labels';
import AppContext from '../../../../context/AppContext';
import Enquiries from '../../Enquiries';
import useFormData from '../../../../hooks/useFormData';

const optionsReferrer = [
  { label: 'An Allied Health Professional', value: 'Allied Health Professional' },
  { label: 'A GP or consultant', value: 'GP or consultant' },
  { label: 'A dental professional', value: 'A dental professional' }
];

const ClinicianFormStep1 = ({ onSubmit, data, form, ...other }) => {
  const { dispatch } = useContext(AppContext);
  const [ formData, setFormData ] = useFormData(data);

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  function handleOfflineClick(e) {
    e.preventDefault();
      
    dispatch({
      type: 'MODAL_SHOW',
      modal: { children: <Enquiries.Referrals /> },
    });
  }
  
  return (
     /* eslint-disable jsx-a11y/anchor-is-valid */
    <Step title="Referrer information" selectedValue={data} {...other}>
      <p>
        If you prefer to do this offline, you can <a role="button" href="" onClick={handleOfflineClick}>refer a patient via a printed form</a>.
      </p>
      
      <Form onSubmit={handleSubmit} onChange={e => setFormData(e.target.form)}>      
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.referrerName} name="referrerName" id="name" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.referrerProfession} name="referrerProfession" id="referrerProfession" required/>
        </Form.Row>
        <Form.Fieldset label={labels.referrerType}>
          {optionsReferrer.map((option, index) => (
            <Form.Radio 
              label={option.label} 
              name="referrerType" 
              id={`referrerType${index}`} 
              value={option.value} 
              required 
              key={index}
            />
          ))}
        </Form.Fieldset>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.referrerEmail} name="referrerEmail" id="email" type="email" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.referrerTelephone} name="referrerTelephone" id="telephone" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.cmcHpcpNumber} name="cmcHpcpNumber" id="cmcHpcpNumber" required/>
        </Form.Row>

        <Button type="submit">Next</Button>
      </Form>
    </Step>
     /* eslint-enable jsx-a11y/anchor-has-content */
  )
};

export default ClinicianFormStep1;