import classNames from 'classnames';
import React from 'react';

import Emphasise from '../Emphasise/Emphasise';
import Icon from '../Icon/Icon';
import { motion } from '../framer-motion-custom';
import * as styles from './Type.module.scss';

const Type = {};

const Heading = ({
  className,
  as: Comp = motion.h1,
  isCentered,
  isWhiteOut,
  isBold,
  tag,
  children,
  ...other
}) => {
  return (
    <Comp
      className={classNames(
        styles.heading,
        {
          [ styles.isCentered ]: isCentered,
          [ styles.isWhiteOut ]: isWhiteOut,
          [ styles.isBold ]: isBold,
        },
        className
      )}
      {...other}
    >
      <Emphasise tag={tag}>{children}</Emphasise>
    </Comp>
  );
};

const H1 = (props) => (
  <Heading className={styles.h1} as={motion.h1} {...props} />
);
const H1Secondary = (props) => (
  <Heading className={styles.h1Secondary} as={motion.h1} {...props} />
);
const H2 = (props) => (
  <Heading className={styles.h2} as={motion.h2} {...props} />
);
const H3 = (props) => (
  <Heading className={styles.h3} as={motion.h3} {...props} />
);
const H4 = (props) => (
  <Heading className={styles.h4} as={motion.h4} {...props} />
);
const SectionHeading = (props) => (
  <Heading {...props} className={styles.sectionHeading} as={motion.h2} />
);
const MetaHeading = ({ as: Comp = motion.h3, icon, children, ...other }) => (
  <Comp {...other} className={styles.metaHeading}>
    {icon && <Icon className={styles.metaIcon} name={icon} />} {children}
  </Comp>
);
const Lead = ({ as: Comp = motion.p, ...other }) => (
  <Comp {...other} className={styles.lead} />
);

Type.H1 = H1;
Type.H1Secondary = H1Secondary;
Type.H2 = H2;
Type.H3 = H3;
Type.H4 = H4;
Type.SectionHeading = SectionHeading;
Type.MetaHeading = MetaHeading;
Type.Lead = Lead;

export default Type;
