import { Accordion, AccordionItem, AccordionButton } from '@reach/accordion';
import React, { useContext } from 'react';

import AppContext from '../../context/AppContext';
import Enquiries from '../Enquiries/Enquiries';
import MegaMenu from '../MegaMenu/MegaMenu';
import Button, { themes, sizes } from '../UI/Button/Button';
import ButtonGroup from '../UI/ButtonGroup/ButtonGroup';
import { CustomAccordionItemPanel } from '../UI/CustomAccordion/CustomAccordion';
import Icon from '../UI/Icon/Icon';
import Link from '../UI/Link/Link';
import { SocialLinks } from '../UI/Social/Social';
import * as styles from './NavFlyout.module.scss';

const NavFlyout = ({ menus, activeNodeIndex, ...other }) => {
  const app = useContext(AppContext);

  function onBookClick() {
    app.dispatch({
      type: 'MODAL_SHOW',
      modal: { children: <Enquiries /> },
    });
  }

  function onContactClick() {
    app.dispatch({
      type: 'MODAL_SHOW',
      modal: { children: <Enquiries.Contact /> },
    });
  }

  function getNavLinkClass(key) {
    return key === 'primary' || key === 'secondary'
      ? styles.navLink
      : styles.navLinkSecondary;
  }

  return (
    <div className={styles.panel}>
      <div className={styles.body}>
        <Accordion
          className={styles.nav}
          collapsible
          defaultIndex={activeNodeIndex}
        >
          {Object.keys(menus).map((key) => {
            const nodes = menus[key];

            return nodes.map((node) => {
              const path = node?.connectedNode?.node?.uri ?? node.url;

              return !!node.children.length ? (
                <AccordionItem
                  className={styles.navItem}
                  uuid={node.id}
                  key={node.id}
                >
                  <AccordionButton className={getNavLinkClass(key)}>
                    {node.label}{' '}
                    <Icon className={styles.navArrow} name="chevron-down" />
                  </AccordionButton>
                  <CustomAccordionItemPanel
                    className={styles.navPanel}
                    innerClass={styles.navPanelInner}
                  >
                    <MegaMenu
                      cols={1}
                      nodes={node.children}
                      menu={key}
                      isReverse={true}
                    />
                  </CustomAccordionItemPanel>
                </AccordionItem>
              ) : (
                <AccordionItem
                  className={styles.navItem}
                  uuid={node.id}
                  key={node.id}
                >
                  <Link className={getNavLinkClass(key)} to={path}>
                    {node.label}
                  </Link>
                </AccordionItem>
              );
            });
          })}
        </Accordion>

        <SocialLinks />
      </div>

      <div className={styles.footer}>
        <ButtonGroup isBlock>
          <Button
            size={sizes.LARGE}
            theme={themes.PRIMARY}
            onClick={onBookClick}
            hideIcon
          >
            Book
          </Button>
          <Button
            size={sizes.LARGE}
            theme={themes.PRIMARY}
            onClick={onContactClick}
            hideIcon
          >
            Contact
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default NavFlyout;