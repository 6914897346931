import { useCallback, useState } from "react";
import getFormDataAsObject from "../utils/get-form-data-as-object";

const useFormData = (obj) => {
  const [ data, setData ] = useState({ ...obj });

  const setFormData = useCallback((form) => {
    const formData = getFormDataAsObject(form);
    setData(formData);
  }, []);

  return [
    data,
    setFormData
  ]
};

export default useFormData;