import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import Icon from '../Icon/Icon';
import * as styles from './Social.module.scss';

const MENU_QUERY = graphql`
  query SocialQuery {
    wpMenu(locations: {eq: SOCIAL}) {
      ...MenuItems
    }
  }
`;

export const SocialLinks = ({
  className,
  items,
  isReverse,
  isVertical = false,
  ...other
}) => {
  const classname = classNames(styles.socialLinks, className, {
    [ styles.isReverse ]: isReverse,
    [ styles.isVertical ]: isVertical,
  });
  const { wpMenu } = useStaticQuery(MENU_QUERY);
  const nodes = items || wpMenu.menuItems.nodes;

  return nodes?.length ? (
    <ul className={classname}>
      {nodes?.map((node, index) => (
        <li className={styles.item} key={index}>
          <a
            className={styles.link}
            href={node.url}
            aria-label={node.label}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name={node.label.toLowerCase()} />
          </a>
        </li>
      ))}
    </ul>
  ) : null;
};

export const ShareLinks = ({ subject, url, ...other }) => {
  return (
    <SocialLinks
      items={[
        {
          label: 'Facebook',
          url: `http://www.facebook.com/share.php?u=${process.env.GATSBY_SITE_URL}${url}`,
        },
        {
          label: 'Twitter',
          url: `https://twitter.com/intent/tweet?url=${process.env.GATSBY_SITE_URL}${url}`,
        },
        {
          label: 'Mail',
          url: `mailto:?subject=${subject}&body=${process.env.GATSBY_SITE_URL}${url}`,
        },
      ]}
      {...other}
    />
  );
};
