import PropTypes from 'prop-types';
import React from 'react';
import ripple from '../../../assets/images/ripple.svg';

const Ripple = ({ className }) => (
  <svg
    className={className}
    width="1679px"
    height="950px"
    viewBox="0 0 1679 950"
  >
    <use xlinkHref={`${ripple}#ripple`}></use>
  </svg>
);

Ripple.propTypes = {
  className: PropTypes.string,
};

export default Ripple;
