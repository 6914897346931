import React from 'react';
import * as styles from './SkipLink.module.scss';

const SkipLink = () => {
  return (
    <nav className={styles.skipLink}>
      Skip to <a href='#main'>content</a> or <a href='#footer'>footer</a>
    </nav>
  )
};

export default SkipLink;
