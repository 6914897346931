import classnames from 'classnames';
import React from 'react';

import Columns from '../UI/Columns/Columns';
import Link from '../UI/Link/Link';
import NavList from '../UI/NavList/NavList';
import NavPanel from '../UI/NavPanel/NavPanel';
import WpImage, { imgSizes } from '../UI/WpImage/WpImage';
import * as styles from './MegaMenu.module.scss';

const MegaMenu = ({ cols, nodes, menu, isReverse = false }) => {
  const isPrimaryNavlist = cols === 1;

  return (
    <Columns
      className={classnames({ [ styles.isReverse ]: isReverse })}
      count={cols}
    >
      {menu === 'tertiary' ? (
        <NavList
          nodes={nodes}
          isReverse={isReverse}
          isPrimary={isPrimaryNavlist}
        />
      ) : (
        nodes.map((node) => {
          const connectedNode = node?.connectedNode?.node;
          const nodeType = connectedNode?.nodeType;
          const path = connectedNode?.uri ?? node.url;

          return (
            <React.Fragment key={node.id}>
              {nodeType === 'Page' && (
                <NavPanel {...node} isReverse={isReverse} path={path} />
              )}

              {nodeType === 'ServiceCategory' && !!node.children.length && (
                <>
                  <h2 className={styles.categoryTitle}>
                    <Link to={path}>{node.label}</Link>
                  </h2>
                  <NavList
                    nodes={node.children}
                    isReverse={isReverse}
                    isPrimary={isPrimaryNavlist}
                  />
                </>
              )}

              {nodeType === 'Location' && (
                <Link className={styles.location} to={path}>
                  {!!connectedNode.locationDetails.gallery?.length && (
                    <WpImage
                      className={styles.locationImg}
                      {...connectedNode.locationDetails.gallery[ 0 ]}
                      size={imgSizes.THUMBNAIL}
                      width={110}
                      height={115}
                    />
                  )}

                  <div className={styles.locationBody}>
                    <h2 className={styles.locationTitle}>{node.label}</h2>
                    <p className={styles.locationDesc}>{node.description}</p>
                  </div>
                </Link>
              )}

              {nodeType === 'LocationCategory' && (
                <Link className={styles.location} to={path}>
                  {!!connectedNode.locations.nodes[ 0 ]?.locationDetails?.gallery
                    ?.length && (
                    <WpImage
                      className={styles.locationImg}
                      {...connectedNode.locations.nodes[ 0 ].locationDetails
                        .gallery[ 0 ]}
                      size={imgSizes.THUMBNAIL}
                      width={110}
                      height={115}
                    />
                  )}

                  <div className={styles.locationBody}>
                    <h2 className={styles.locationTitle}>{node.label}</h2>
                    <p className={styles.locationDesc}>{node.description}</p>
                  </div>
                </Link>
              )}
            </React.Fragment>
          );
        })
      )}
    </Columns>
  );
};

export default MegaMenu;