
import React, { useEffect, useMemo, useState } from 'react';
import Step from '../Step';
import Form, { themes as fieldThemes } from '../../UI/Form/Form';
import getFormDataAsObject from '../../../utils/get-form-data-as-object';
import Button, { themes as ButtonThemes }  from '../../UI/Button/Button';
import { graphql, useStaticQuery } from 'gatsby';
import * as utilities from '../../../styles/utilities.module.scss';
import Type from '../../UI/Type/Type';

const optionsContactMethod = [
  { label: 'Email', value: null },
  { label: 'Phone', value: null }
];

const optionsWhereHeard = [
  { label: 'My GP', value: null },
  { label: 'My consultant', value: null },
  { label: 'Social media', value: null },
  { label: 'Word of mouth', value: null },
  { label: 'Local press', value: null },
  { label: 'Local event', value: null },
  { label: 'Employer', value: null },
  { label: 'Local sports club', value: null },
  { label: 'Football or rugby match', value: null },
  { label: 'Google search', value: null },
  { label: 'Other - please state', value: 'other' }
];

const optionUsingService = [
  { label: 'I want to take control of my health', value: null },
  { label: 'I am displaying worrying symptoms', value: null },
  { label: 'Other - please state', value: 'other' }
];

function isChecked(formData, key, value){
  const obj = formData ? formData[ key ] : null;

  if( Array.isArray(obj) ) {
    return obj.some(v => 
      v === value
    )
  } else {
    return obj === value
  }
}

const BookStep5 = ({ onSubmit, data, form, labels, ...other }) => {
  const [ date, setDate ] = useState(null);
  const [ isAsap, setIsAsap ] = useState(null);
  const [ formData, setFormData ] = useState({ ...data });

  const { 
    allWpLocation: {
      nodes: locations
    }
  } = useStaticQuery(
    graphql`
      query BookStep4Query {
        allWpLocation {
          nodes {
            title
            locationDetails {
              timeSlots {
                label
                timeSlot {
                  from
                  until
                }
              }
            }
          }
        }
      }
    `
  );

  const times = useMemo(() => {
    const selectedLocation = form.find(({ formData }) => formData?.centre)?.formData?.centre;
    const match = locations.find(({ title }) => {
      return title === selectedLocation;
    });
    return match?.locationDetails.timeSlots || [];
  }, [ form, locations ]);

  function handleWhenChange(isAsap){
    setIsAsap(isAsap);
  }

  function handleDateChange(date, e){
    setDate(date);
  }

  function getFormData(form) {
    const obj = getFormDataAsObject(form);

    if( obj.whereHeard !== 'other' ) {
      delete obj.otherWhereHeard;
    }

    if( obj.whyUsingThisService !== 'other' ) {
      delete obj.otherWhyUsingThisService;
    }

    return obj;
  }

  function handleFormChange(e) {
    setFormData(getFormData(e.target.form));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = getFormData(e.target);
    onSubmit(formData);
  }

  useEffect(() => {
    setFormData({ ...data });
  }, [ data ])
  
  return (
    <Step title="Appointment information" {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>

        <Form.Fieldset label="When would like our Care Co-ordinator to contact you?" noValidate>        
          <Form.Row>
            <Form.Radio 
              label="ASAP" 
              id="when1" 
              name="when"
              value="asap"
              onChange={handleWhenChange.bind(null, true)} 
              onClick={handleWhenChange.bind(null, true)}
              defaultChecked={isAsap}
              required
              />
            <Form.Radio 
              label="Specify a date and time" 
              id="when2" 
              name="when"
              value="custom"
              onChange={handleWhenChange.bind(null, false)} 
              onClick={handleWhenChange.bind(null, false)}
              defaultChecked={isAsap === false}
              required
              />
          </Form.Row>

          {isAsap === false &&
            <>
              <p>Please note that we cannot guarantee your preferred day and time slot, but will do our best to accommodate your preference.</p>
              
              <Form.Row>
                <Form.DatePicker 
                  theme={fieldThemes.PRIMARY_FILLED} 
                  label="Date"
                  name="preferredDate" 
                  id="date" 
                  selected={date} 
                  onChange={handleDateChange} 
                  dateFormat="d MMMM yyyy"
                  required />
              </Form.Row>

              <Form.CheckboxList 
                label="Select preferred time slot"
                name="preferredTimeslots"
                listId={0}
                options={[
                  { from: '8:00 am', until: '1:00 pm' },
                  { from: '1:00 pm', until: '6:00 pm' }
                ].map(({ from, until }) => {
                  const label = `${from} - ${until}`;
                  const value = label;

                  return { 
                    label, 
                    value,
                    checked: isChecked(formData, 'preferredTimeslots', value)
                  }
                })} 
                required={!formData.preferredTimeslots}
                />
            </>
          }

          <Form.Fieldset label={labels.preferredContactMethod}>
            {optionsContactMethod.map((option, index) => (
              <Form.Radio 
                label={option.label} 
                name="preferredContactMethod" 
                id={`preferredContactMethod${index}`} 
                value={option.value || option.label} 
                required 
                key={index}
              />
            ))}
          </Form.Fieldset>  
        </Form.Fieldset>

        <Form.Fieldset label="Where did you hear about this health check service?">
          {optionsWhereHeard.map((option, index) => (
            <Form.Radio 
              label={option.label} 
              name="whereHeard" 
              id={`whereHeard${index}`} 
              value={option.value || option.label} 
              key={index}
            />
          ))}

          {formData.whereHeard === 'other' &&
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={'Other'} name="otherWhereHeard" id="otherWhereHeard"/>
            </Form.Row>
          }
        </Form.Fieldset> 

        <Form.Fieldset label="What prompted you to use this service?">
          {optionUsingService.map((option, index) => (
            <Form.Radio 
              label={option.label} 
              name="whyUsingThisService" 
              id={`whyUsingThisService${index}`} 
              value={option.value || option.label} 
              key={index}
            />
          ))}

          {formData.whyUsingThisService === 'other' &&
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={'Other'} name="otherWhyUsingThisService" id="otherWhyUsingThisService"/>
            </Form.Row>
          }
        </Form.Fieldset> 

        <Button theme={ButtonThemes.PRIMARY} type="submit">Submit booking</Button>
      </Form>
    </Step>
  )
};

export default BookStep5;