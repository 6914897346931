import React, { useContext, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import AppContext from '../context/AppContext';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import PageTransition from './PageTransition/PageTransition';
import Flyout from './UI/Flyout/Flyout';
import CookieBar from './UI/CookieBar/CookieBar';
import SkipLink from './UI/SkipLink/SkipLink';
import { useSeoData } from '../hooks/useSeoData';

import 'normalize.css';
import '../styles/base.scss';
import '../styles/wordpress.scss';
import '../styles/fonts.scss';

const Layout = ({ children, location }) => {
  const { state, dispatch } = useContext(AppContext);
  const flyoutTriggerRef = useRef(null);
  const seo = useSeoData();
  const handleFlyoutClose = useCallback(
    (e) => {
      dispatch({ type: 'MODAL_HIDE' });
    },
    [ dispatch ]
  );

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Helmet>
        <meta name="google" content="nositelinkssearchbox" />
        <noscript>    
          {`
            <style>
              [style*="opacity:0"] {
                opacity: 1 !important;
              }
              
              [style*="transform"] {
                transform: none !important;
              }
            </style>
          `}
        </noscript>
      </Helmet>
      
      <CookieBar/>
      <SkipLink/>

      <Header burgerRef={flyoutTriggerRef} />

      <main id="main">
        <PageTransition location={location}>{children}</PageTransition>
      </main>

      <Footer />

      <Flyout
        {...state.modal}
        triggerRef={flyoutTriggerRef}
        showCloseBtn={false}
        isActive={state.isModalActive}
        onCloseClick={handleFlyoutClose}
      >
        {state.modal?.children}
      </Flyout>

    </SEOContext.Provider>
  );
};

export default Layout;
