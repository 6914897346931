import PatientFormStep1 from "./PatientFormStep1";
import PatientFormStep2 from "./PatientFormStep2";
import PatientFormStep3 from "./PatientFormStep3";
import PatientFormStep4 from "./PatientFormStep4";
import PatientFormStep5 from "./PatientFormStep5";
import PatientFormStep6 from "./PatientFormStep6";
import PatientFormStep7 from "./PatientFormStep7";

const PatientForm = [
  {
    id: 'step-1',
    step: PatientFormStep1, 
    formData: null, 
    isOpen: true,
    isDisabled: false
  },
  {
    id: 'step-2',
    step: PatientFormStep2, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-3',
    step: PatientFormStep3, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-4',
    step: PatientFormStep4, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-5',
    step: PatientFormStep5, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-6',
    step: PatientFormStep6, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-7',
    step: PatientFormStep7, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  }
];

export default PatientForm;