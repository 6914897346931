import classNames from 'classnames';
import React, { useState } from 'react';
import * as styles from './WpImage.module.scss';

export const imgSizes = {
  THUMBNAIL: 'thumbnail',
  MEDIUM: 'medium',
  MEDIUM_LARGE: 'medium_large',
  LARGE: 'large',
  XLARGE: '1536x1536',
  PORTRAIT_SMALL: 'portrait-small',
  PORTRAIT_MEDIUM: 'portrait-medium',
  PORTRAIT_LARGE: 'portrait-large',
  LETTERBOX_SMALL: 'letterbox-small',
  LETTERBOX_MEDIUM: 'letterbox-medium',
  LETTERBOX_LARGE: 'letterbox-large',
  LETTERBOX_XLARGE: 'letterbox-xlarge',
};

export function getAspectRatio(width, height) {
  return Math.round((height / width) * 100) / 100;
}

export function getSource(
  sourceUrl,
  mediaDetails,
  { media, size: imgSize },
  dimensions
) {
  if (!mediaDetails) return null;

  const sizes = mediaDetails.sizes || [
    {
      sourceUrl,
      width: mediaDetails.height,
      height: mediaDetails.width,
      name: imgSize
    }
  ];

  const size = sizes.find((s) => s.name === imgSize) || {
    width: mediaDetails.width,
    height: mediaDetails.height,
  };

  const aspectRatio = getAspectRatio(size.width, size.height);
  const matchingSizes = sizes.filter((s) => {
    return getAspectRatio(s.width, s.height) === aspectRatio;
  });
  const srcSet = matchingSizes
    .map((size) => `${size.sourceUrl} ${size.width}w`)
    .join(', ');
  const matchingSize = size.sourceUrl
    ? size
    : matchingSizes[ matchingSizes.length - 1 ];

  return dimensions
    ? {
        aspectRatio,
        width: Number(dimensions.width ? dimensions.width : matchingSize.width),
        height: Number(dimensions.height ? dimensions.height : matchingSize.height),
        src: matchingSize.sourceUrl,
        srcSet: matchingSize.sourceUrl,
      }
    : {
        aspectRatio,
        sizes: `(max-width: ${matchingSize.width}px) 100vw, ${matchingSize.width}px`,
        src: matchingSize.sourceUrl,
        srcSet,
        media,
      };
}

const FixedImage = ({ className, width, height, source, ...other }) => {
  return (
    <div className={classNames(styles.container, className)} style={{ width, height }}>
      <img 
        className={styles.image} 
        {...source} 
        alt="" 
        {...other} />
    </div>
  )
}

const FluidImage = ({ className, sources, ...other }) => {
  const [ ratio, setRatio ] = useState(sources[ 0 ].aspectRatio);
  const placeholderHeight = `${100 * ratio}%`;

  function handleLoad(e) {
    const source = sources.find(source => {
      return source.srcSet.includes(e.target.currentSrc);
    });

    setRatio(source.aspectRatio);
  }

  return (
    <div className={classNames(styles.container, className)} style={{ "--placeholder-height": placeholderHeight }}>
      {
        sources.length > 1 ? (
          <picture>
            {sources.map(({ src, aspectRatio, ...source }, index) => (
              <source {...source} key={index} />
            ))}
            <img 
              className={styles.image} 
              {...sources[ sources.length - 1 ]} 
              alt="" 
              loading="lazy" 
              onLoad={handleLoad}
              {...other} />
          </picture>
        ) : (
          <img 
            className={styles.image}
            {...sources[ 0 ]} 
            alt="" 
            loading="lazy"
            {...other} />
        )
      }
    </div>
  )
}

const WpImage = ({
  size = imgSizes.LARGE,
  sourceUrl,
  mediaDetails,
  width,
  height,
  ...other
}) => {
  if (width && height) {
    const { aspectRatio, ...source } = getSource(sourceUrl, mediaDetails, { size }, { width, height });
    return <FixedImage width={width} height={height} source={source} {...other}  />
  } else {
    const sources = [];

    if (Array.isArray(size)) {
      for (let index = 0; index < size.length; index++) {
        const source = getSource(sourceUrl, mediaDetails, size[ index ]);
        sources.push(source);
      }
    } else {
      const source = getSource(sourceUrl, mediaDetails, { size });
      sources.push(source);
    }

    sources.reverse();

    return <FluidImage sources={sources} {...other}/>
  }
};

export default WpImage;
