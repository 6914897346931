import React from 'react';
import useResponsive from '../../hooks/useResponsive';
import MegaMenu from '../MegaMenu/MegaMenu';
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
} from '../UI/Dropdown/Dropdown';
import Icon from '../UI/Icon/Icon';
import * as styles from './NavPrimary.module.scss';

const NavPrimary = ({ nodes, onNodeClick }) => {
  const { isXLarge } = useResponsive();

  return nodes.length > 0 ? (
    <ul className={styles.menu}>
      {nodes.map((node, index) => {
        return (
          <li className={styles.item} key={node.id}>
            {isXLarge && !!node.children.length ? (
              <Dropdown key={node.id}>
                <DropdownButton className={styles.link}>
                  <span className={styles.linkInner}>
                    {node.label}{' '}
                    <Icon className={styles.icon} name="chevron-down" />
                  </span>
                </DropdownButton>
                <DropdownMenu
                  className={styles.megaMenu}
                  position={(domRect) => ({
                    top: domRect.bottom + document.documentElement.scrollTop,
                    left: 0,
                  })}
                >
                  <div className={styles.inner}>
                    <MegaMenu
                      cols={node.megaMenu.columnCount}
                      nodes={node.children}
                      isReverse={false}
                    />
                  </div>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <button
                className={styles.link}
                onClick={(e) => onNodeClick(index)}
              >
                <span className={styles.linkInner}>{node.label}</span>
              </button>
            )}
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default NavPrimary;