import ProstateScreenStep1 from './ProstateScreenStep1';
import ProstateScreenStep2 from './ProstateScreenStep2';
import ProstateScreenStep3 from './ProstateScreenStep3';
import ProstateScreenStep4 from './ProstateScreenStep4';
import ProstateScreenStep5 from './ProstateScreenStep5';
import ProstateScreenStep6 from './ProstateScreenStep6';

const STEPS = [
  {
    id: 'step-1',
    step: ProstateScreenStep1, 
    formData: null, 
    isOpen: true,
    isDisabled: false
  },
  {
    id: 'step-2',
    step: ProstateScreenStep2, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-3',
    step: ProstateScreenStep3, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-4',
    step: ProstateScreenStep4, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-5',
    step: ProstateScreenStep5, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-6',
    step: ProstateScreenStep6, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
];

export default STEPS;