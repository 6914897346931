import React, { useState } from 'react';
import { subYears } from 'date-fns';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button from '../../../UI/Button/Button';
import labels from "../labels";
import useFormData from '../../../../hooks/useFormData';
import getFormDataAsObject from '../../../../utils/get-form-data-as-object';

const optionsGender = [
  { label: 'Male (including trans man)', value: null },
  { label: 'Female (including trans woman)', value: null },
  { label: 'Non-binary', value: null },
  { label: 'Other (not listed)', value: 'other' },
];

const minAge = 16;
const maxAge = 110;

const ClinicianFormStep2 = ({ onSubmit, data, form, ...other }) => {
  const [ formData, setFormData ] = useState({ ...data });
  const [ birthDate, setBirthDate ] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  function handleFormChange(e) {
    const obj = getFormDataAsObject(e.target.form);

    if( obj.gender !== 'other' ) {
      delete obj.patientGenderOther;
    }

    setFormData(obj);
  }

  function handleBirthDateChange(date, e){
    setBirthDate(date);
  }

  return (
    <Step title="Patient details" selectedValue={data} {...other}>     
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>   
        <Form.Fieldset label={labels.patientGender} noValidate>
          {optionsGender.map((option, index) => (
            <Form.Radio 
              label={option.label} 
              name="patientGender" 
              id={`patientGender${index}`} 
              value={option.value || option.label} 
              checked={formData?.patientGender === (option.value || option.label)}
              required 
              key={index}
            />
          ))}

          {formData.patientGender === 'other' &&
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={'Other gender'} name="patientGenderOther" id="patientGenderOther" required/>
            </Form.Row>
          }
        </Form.Fieldset>

        <Form.Row>
          <Form.Input theme={fieldThemes.SECONDARY_FILLED} label={labels.patientFirstName} name="patientFirstName" id="firstName" value={formData?.firstName} required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.patientSurname} name="patientSurname" id="surname" value={formData?.surname} required/>
        </Form.Row>
        <Form.Row>
          <Form.DatePicker 
            theme={fieldThemes.PRIMARY_FILLED} 
            label={labels.dob}
            name="patientDob" 
            id="dob" 
            selected={birthDate} 
            onChange={handleBirthDateChange} 
            minDate={subYears(new Date(), maxAge)}
            maxDate={subYears(new Date(), minAge)}
            dateFormat="d MMMM yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            required />
        </Form.Row>
        <Form.Row>
          <Form.TextArea theme={fieldThemes.PRIMARY_FILLED} label={labels.patientAddress} name="patientAddress" id="address" value={formData?.address} rows="4" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.patientPostcode} name="patientPostcode" id="postcode" value={formData?.postcode} required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.patientEmail} name="patientEmail" id="email" type="email" value={formData?.email} required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.patientTelephone} name="patientTelephone" id="telephone" value={formData?.telephone} required/>
        </Form.Row>

        <Button type="submit">Next</Button>
      </Form>
    </Step>
  )
};

export default ClinicianFormStep2;