const getFormDataAsObject = form => {
  const object = {};
  const formData = new FormData(form);

  formData.forEach((value, key) => {
    if(!Reflect.has(object, key)){
      object[ key ] = value;
      return;
    }

    if(!Array.isArray(object[ key ])){
        object[ key ] = [ object[ key ] ];    
    }

    object[ key ].push(value);
  });

  return object;
};

export default getFormDataAsObject;