// extracted by mini-css-extract-plugin
export var h1 = "Type-module--h1--453e7";
export var h1Secondary = "Type-module--h1Secondary--5d3fe";
export var h2 = "Type-module--h2--e38f7";
export var h3 = "Type-module--h3--709b0";
export var h4 = "Type-module--h4--bdf33";
export var heading = "Type-module--heading--0bae3";
export var isBold = "Type-module--isBold--818b8";
export var isCentered = "Type-module--isCentered--fbd87";
export var isWhiteOut = "Type-module--isWhiteOut--ebaaa";
export var lead = "Type-module--lead--e8c93";
export var metaHeading = "Type-module--metaHeading--f888d";
export var metaIcon = "Type-module--metaIcon--fa755";
export var sectionHeading = "Type-module--sectionHeading--e0a37";