import React from 'react';

import HtmlContent from '../HtmlContent/HtmlContent';

const Emphasise = ({ children, tag = 'span', ...other }) => {
  const emphasised = children?.replace(/\*\*([^*]+)\*\*/g, '<em>$1</em>');

  return <HtmlContent {...other} content={emphasised} as={tag} />;
};

export default Emphasise;
