import React from 'react';
import * as styles from "./Step.module.scss";

const SelectedValues = ({ data, labels }) => {
  if (!data) return null;

  function getStringFromValue(value){
    if( value instanceof File ) {
      return value.name;
    } else if ( value === "on") {
      return "True";
    } else {
      return value || `-`;
    }
  }

  return (
    <div className={styles.stepValues}>
      {typeof data === 'object' ?
          Object.keys(data).map(key => {
            return (
              <dl className={styles.stepValueList} key={key}>
                <dt>{labels[ key ]}:</dt>
                <dd>{ 
                  Array.isArray(data[ key ]) ? 
                    <ul>
                      {data[ key ].map((value, index) => 
                        <li key={index}>
                          {getStringFromValue(value)}
                        </li>
                      )}
                    </ul>
                    : 
                    getStringFromValue(data[ key ])
                  }
                </dd>
              </dl>
            )
          })
        :
        data  
      }
    </div>
  )
}

const Step = ({ 
  title, 
  isOpen, 
  isDisabled, 
  onClick, 
  index, 
  number,
  children, 
  selectedValue,
  labels,
  ...other
}) => {
  function handleToggle() {
    if(selectedValue) {
      onClick(index)
    }
  }

  return (
    <div className={styles.step} {...other} style={isDisabled ? { opacity: 0.5, pointerEvents: 'none' } : null}>
      <button className={styles.stepTitle} onClick={handleToggle} aria-expanded={isOpen}>
        {`${number}. ${title}`}
        {selectedValue && !isOpen && !isDisabled && <span className={styles.stepEdit}>Edit</span>}
      </button>
     
      {!isDisabled &&      
        <div className={styles.stepBody}>
          {!isOpen && <SelectedValues data={selectedValue} labels={labels}/>}
          
          <div style={{ display: !isOpen ? 'none' : null }}>
            {children}
          </div>
        </div>
      }
     
    </div>
  )
};

export default Step;