import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext, useRef } from 'react';
import Logo from '../../assets/images/primehealth-logo.inline.svg';
import AppContext from '../../context/AppContext';
import useOnScreen from '../../hooks/useOnScreen';
import flatListToHierarchical from '../../utils/flatListToHierarchical';
import Enquiries from '../Enquiries/Enquiries';
import NavFlyout from '../NavFlyout/NavFlyout';
import NavPrimary from '../NavPrimary/NavPrimary';
import NavSecondary from '../NavSecondary/NavSecondary';
import Button, { themes } from '../UI/Button/Button';
import Hamburger from '../UI/Hamburger/Hamburger';
import Icon from '../UI/Icon/Icon';
import Link from '../UI/Link/Link';
import * as styles from './Header.module.scss';

const HEADER_QUERY = graphql`
  query HeaderMenuQuery {
    primary: wpMenu(locations: {eq: PRIMARY}) {
      ...MenuItems
    }

    secondary: wpMenu(locations: {eq: SECONDARY}) {
      ...MenuItems
    }

    tertiary: wpMenu(locations: {eq: TERTIARY}) {
      ...MenuItems
    }

    wp {
      themeGeneralSettings {
        siteOptions {
          email
          fax
          tel
        }
      }
    }
  }
`;

const Header = ({ burgerRef }) => {
  const { state, dispatch } = useContext(AppContext);
  const ref = useRef(null);
  const isHeaderInView = useOnScreen(ref, { rootMargin: '-140px', defaultState: true });
  const { 
    wp: { themeGeneralSettings: { siteOptions } }, 
    ...menus
  } = useStaticQuery(HEADER_QUERY);
  const nodesPrimary = flatListToHierarchical(menus.primary.menuItems.nodes);
  const nodesSecondary = flatListToHierarchical(menus.secondary.menuItems.nodes);
  const nodesTertiary = flatListToHierarchical(menus.tertiary.menuItems.nodes);

  function handleBurgerClick(e) {
    if (!state.isModalActive) {
      openFlyout();
    } else {
      dispatch({ type: 'MODAL_HIDE' });
    }
  }

  function handleBookClick(e) {
    dispatch({
      type: 'MODAL_SHOW',
      modal: {
        children: <Enquiries />
      },
    });
  }

  function openFlyout(index) {
    dispatch({
      type: 'MODAL_SHOW',
      modal: {
        children: (
          <NavFlyout
            menus={{
              primary: nodesPrimary,
              secondary: nodesSecondary,
              tertiary: nodesTertiary,
            }}
            activeNodeIndex={index}
          />
        ),
        isScrollable: false,
      },
    });
  }

  return (
    <header className={styles.container} role="banner" ref={ref}>
      <nav className={styles.topSection}>
        <NavSecondary nodes={nodesSecondary} />
      </nav>

      <nav className={styles.mainSection}>
        <div className={styles.brand}>
          <Link to="/">
            <Logo className={styles.logo} />
          </Link>
        </div>

        <NavPrimary nodes={nodesPrimary} onNodeClick={openFlyout}/>

        <div className={styles.controls}>
          <a
            className={styles.tel}
            href={`tel:+44${siteOptions.tel.replace(/ /g, '')}`}
          >
            <Icon name="phone" /> {siteOptions.tel}
          </a>

          <Button
            className={styles.button}
            theme={themes.OUTLINE}
            onClick={handleBookClick}
            hideIcon
          >
            Book
          </Button>
        </div>
      </nav>

      <Hamburger
        ref={burgerRef}
        isActive={state.isModalActive}
        isFixed={!isHeaderInView}
        onClick={handleBurgerClick}
      />
    </header>
  );
};

export default Header;