import React, { useState, useRef } from 'react';

import { motion, AnimatePresence, useReducedMotion } from '../UI/framer-motion-custom';

export const PageTransitionContext = React.createContext({
  isTransitioning: false
});

const PageTransition = ({ children, location }) => {
  const shouldReduceMotion = useReducedMotion();
  const [ isTransitioning, setTransitioning ] = useState(false);
  const firstUpdate = useRef(true);
  const duration = 0.35;

  const variants = {
    initial: {
      opacity: 0,
      y: -20,
      transition: {
        when: 'beforeChildren',
      },
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: duration,
        delay: duration,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration,
        when: 'beforeChildren',
      },
    },
  };

  function handleExitComplete() {
    if (window.__navigatingToLink) {
      window.scrollTo(0, 0);
      window.__navigatingToLink = false;
    }

    setTransitioning(false);
  }

  function handleAnimationStart() {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setTransitioning(true);
  }

  return shouldReduceMotion ? children : (
    <AnimatePresence initial={false} onExitComplete={handleExitComplete}>
      <motion.div
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        onAnimationStart={handleAnimationStart}
      >
        <PageTransitionContext.Provider value={{ isTransitioning }}>
          {children}
        </PageTransitionContext.Provider>
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
