import GpHealthCheckStep1 from './GpHealthCheckStep1';
import GpHealthCheckStep2 from './GpHealthCheckStep2';
import GpHealthCheckStep3 from './GpHealthCheckStep3';
import GpHealthCheckStep4 from './GpHealthCheckStep4';
import GpHealthCheckStep5 from './GpHealthCheckStep5';

const STEPS = [
  {
    id: 'step-1',
    step: GpHealthCheckStep1, 
    formData: null, 
    isOpen: true,
    isDisabled: false
  },
  {
    id: 'step-2',
    step: GpHealthCheckStep2, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-3',
    step: GpHealthCheckStep3, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-4',
    step: GpHealthCheckStep4, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  },
  {
    id: 'step-5',
    step: GpHealthCheckStep5, 
    formData: null, 
    isOpen: false,
    isDisabled: true
  }
];

export default STEPS;