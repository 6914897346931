import React, {
  useEffect,
  useRef,
  useContext,
  useCallback,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { globalHistory } from '@reach/router';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import AppContext from '../../../context/AppContext';
import useEventListener from '../../../hooks/useEventListener';
import { motion, AnimatePresence } from '../framer-motion-custom';
import { DropdownContext } from './Dropdown';
import * as styles from './Dropdown.module.scss';

const FOCUSSABLE_ELEMENTS = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

const DropdownMenu = ({
  className,
  children,
  position,
  align = 'bottom-center',
  isFixed
}) => {
  const app = useContext(AppContext);
  const { state, dispatch } = useContext(DropdownContext);
  const ref = useRef(null);
  const historyRef = useRef(null);
  const positionRef = useRef(null);
  const [ allFocussable, setAllFocussable ] = useState(null);
  const [ menuFocussable, setMenuFocussable ] = useState(null);
  const { buttonRef: triggerRef } = state;
  const menuClassName = className || classNames(styles.menu, styles[ camelCase(align) ]);
  const menuPos = state.isOpen ? getPosition() : {};

  function getPosition() {
    if (positionRef.current) {
      return positionRef.current;
    }

    if (position) {
      positionRef.current = position(state.buttonPosition);
    } else if (state.buttonPosition) {
      const pos = {};
      const { buttonPosition } = state;

      switch (align) {
        case 'top-left':
          pos.top = buttonPosition.top;
          pos.left = buttonPosition.left;
          break;
        case 'bottom-left':
          pos.top = buttonPosition.bottom;
          pos.left = buttonPosition.left;
          break;
        case 'bottom-center':
        default:
          pos.top = buttonPosition.bottom;
          pos.left = buttonPosition.left + buttonPosition.width / 2;
          break;
      }

      pos.position = isFixed ? 'fixed' : 'absolute';

      if (!isFixed) {
        pos.top = pos.top + window.pageYOffset;
      }

      positionRef.current = pos;
    }

    return positionRef.current;
  }

  const handleDocumentClick = useCallback(
    (e) => {
      if (!ref.current) return;

      const isOutsideClick =
        !ref.current.contains(e.target) &&
        !triggerRef.current?.contains(e.target);

      if (isOutsideClick && state.isOpen) {
        dispatch({ type: 'CLOSE' });
      }
    },
    [ dispatch, state.isOpen, ref, triggerRef ]
  );

  const handleKeydown = useCallback(
    (e) => {
      if (!state.isOpen) return;

      const key = e.which || e.keyCode;
      const index = menuFocussable.indexOf( document.activeElement );

      // Up key
      if ( key === 38 ) {
        e.preventDefault();
        const nextElement = menuFocussable[ index - 1 ] || menuFocussable[ 0 ];
        nextElement.focus();
      }

      // Down key
      if ( key === 40 ) {
        e.preventDefault();
        const nextElement = menuFocussable[ index + 1 ] || menuFocussable[ menuFocussable.length - 1 ];
        nextElement.focus();
      }

      // Tab key
      if( key === 9 ) {
        const nextElement = menuFocussable[ e.shiftKey ? index - 1 : index + 1 ];
        // Move focus to next focussable element
        if( !nextElement ) {
          e.preventDefault();
          const index = allFocussable.indexOf( triggerRef.current );
          allFocussable[ e.shiftKey ? index - 1 : index + 1 ].focus();
          dispatch({ type: 'CLOSE' });
        }
      }

      // Esc key
      if (key === 27) {
        dispatch({ type: 'CLOSE' });
        window.setTimeout(() => triggerRef.current.focus(), 0);
      }
    },
    [ allFocussable, dispatch, menuFocussable, state.isOpen, triggerRef ]
  );

  useEventListener('keydown', handleKeydown);
  useEventListener('mouseup', handleDocumentClick);

  useEffect(() => {
    if (state.isOpen) {
      ref.current.focus();
    } else {
      positionRef.current = null;
    }
  }, [ state.isOpen ]);

  useEffect(() => {
    historyRef.current = globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && state.isOpen) {
        dispatch({ type: 'CLOSE' });
      }
    });

    return historyRef.current;
  }, [ dispatch, state.isOpen ]);

  useEffect(() => {
    if ( !state.isOpen ) return;

    const allFocussable = Array.prototype.filter.call( 
      document.querySelectorAll( FOCUSSABLE_ELEMENTS ),
      checkVisibility
    );

    const menuFocussable = Array.prototype.filter.call( 
      ref.current.querySelectorAll( FOCUSSABLE_ELEMENTS ),
      checkVisibility
    );

    function checkVisibility( element ){
      return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
    }

    setAllFocussable( allFocussable );
    setMenuFocussable( menuFocussable );
  }, [ state.isOpen ]);

  return app.state.portalNode
    ? ReactDOM.createPortal(
        <AnimatePresence>
          {state.isOpen && (
            <motion.div
              id={state.menuId}
              className={menuClassName}
              aria-labelledby={state.buttonId}
              tabIndex={-1}
              initial={{
                ...menuPos,
                top: menuPos.top + 30,
                opacity: 0,
              }}
              animate={{
                ...menuPos,
                opacity: 1,
              }}
              exit={{
                ...menuPos,
                top: menuPos.top + 30,
                opacity: 0,
              }}
              ref={ref}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>,
        app.state.portalNode
      )
    : null;
};

export default DropdownMenu;