// extracted by mini-css-extract-plugin
export var button = "Button-module--button--094fe";
export var hasIcon = "Button-module--hasIcon--aebcd";
export var icon = "Button-module--icon--81810";
export var isBlock = "Button-module--isBlock--27c1d";
export var isCard = "Button-module--isCard--a452b";
export var isReverse = "Button-module--isReverse--afb2e";
export var sizeDefault = "Button-module--sizeDefault--3bc71";
export var sizeLarge = "Button-module--sizeLarge--462dc";
export var themeLink = "Button-module--themeLink--6d634";
export var themeLinkSimple = "Button-module--themeLinkSimple--7599f";
export var themeOutline = "Button-module--themeOutline--2138d";
export var themePrimary = "Button-module--themePrimary--b50fc";
export var themeSecondary = "Button-module--themeSecondary--9fd1b";