
import React, { useState } from 'react';
import { subYears } from 'date-fns';
import Step from '../../Step';
import Form, { themes as fieldThemes } from '../../../UI/Form/Form';
import Button, { themes as ButtonThemes } from '../../../UI/Button/Button';
import labels from '../labels';
import useFormData from '../../../../hooks/useFormData';
import getFormDataAsObject from '../../../../utils/get-form-data-as-object';

const optionsGender = [
  { label: 'Male (including trans man)', value: null },
  { label: 'Female (including trans woman)', value: null },
  { label: 'Non-binary', value: null },
  { label: 'Other (not listed)', value: 'other' },
];

const minAge = 16;
const maxAge = 110;

const PatientFormStep7 = ({ onSubmit, data, form, ...other }) => {
  const [ birthDate, setBirthDate ] = useState(null);
  const [ formData, setFormData ] = useState({ ...data });

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(formData);
  }

  function handleFormChange(e) {
    const obj = getFormDataAsObject(e.target.form);

    if( obj.gender !== 'other' ) {
      delete obj.otherGender;
    }

    setFormData(obj);
  }

  function handleBirthDateChange(date, e){
    setBirthDate(date);
  }
  
  return (
    <Step title="Your details" {...other}>
      <Form onSubmit={handleSubmit} onChange={handleFormChange}>      
        <Form.Fieldset label="Gender" noValidate>
          {optionsGender.map((option, index) => (
            <Form.Radio 
              label={option.label} 
              name="gender" 
              id={`gender${index}`} 
              value={option.value || option.label} 
              required 
              key={index}
            />
          ))}

          {formData.gender === 'other' &&
            <Form.Row>
              <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={'Other gender'} name="otherGender" id="otherGender" required/>
            </Form.Row>
          }
        </Form.Fieldset>

        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.firstName} name="firstName" id="firstName" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.surname} name="surname" id="surname" required/>
        </Form.Row>
        <Form.Row>
          <Form.DatePicker 
            theme={fieldThemes.PRIMARY_FILLED} 
            label={labels.dob}
            name="dob" 
            id="dob" 
            selected={birthDate} 
            onChange={handleBirthDateChange} 
            minDate={subYears(new Date(), maxAge)}
            maxDate={subYears(new Date(), minAge)}
            dateFormat="d MMMM yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            required />
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.email} name="email" id="email" type="email" required/>
        </Form.Row>
        <Form.Row>
          <Form.Input theme={fieldThemes.PRIMARY_FILLED} label={labels.telephone} name="telephone" id="telephone" required/>
        </Form.Row>
        <Form.Row>
          <Form.TextArea label={labels.comments} name="comments" id="comments" rows="4" theme={fieldThemes.PRIMARY_FILLED}/>
        </Form.Row>
        <Form.Row>
          <Form.Checkbox label="I am happy to occasionally be informed you of new services, exclusive price offers and educational events." name="marketing" id="marketing"/>
          <Form.Checkbox label={<>I agree to the <a href="/privacy-policy/" target='_blank'>privacy policy</a>.</>} name="privacyPolicy" id="privacyPolicy" required/>
        </Form.Row>
        
        <Button theme={ButtonThemes.PRIMARY} type="submit">Request your scan</Button>
      </Form>
    </Step>
  )
};

export default PatientFormStep7;