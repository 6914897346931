import classNames from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';

import AnimateReveal from '../../AnimateReveal/AnimateReveal';
import { motion } from '../framer-motion-custom';
import * as styles from './Grid.module.scss';

export const Grid = ({
  as: Comp = 'div',
  className,
  children,
  hasGutters = true,
  justify,
  align,
  isBordered = false,
  ...other
}) => {
  const cssClass = classNames(
    styles.grid,
    {
      [ styles.gridWithGutters ]: hasGutters,
      [ styles[ `justify${capitalize(justify)}` ] ]: !!justify,
      [ styles[ `align${capitalize(align)}` ] ]: !!align,
      [ styles.isBordered ]: isBordered,
    },
    className
  );

  return (
    <Comp className={cssClass} {...other}>
      {children}
    </Comp>
  );
};

Grid.propTypes = {};

export const GridItem = ({
  as: Comp = 'div',
  className,
  children,
  sm,
  md,
  lg,
  xl,
  xxl,
  pull,
  ...other
}) => {
  const classname = classNames(
    styles.gridItem,
    {
      [ styles[ 'colSpanSm' + sm ] ]: !!sm,
      [ styles[ 'colSpanMd' + md ] ]: !!md,
      [ styles[ 'colSpanLg' + lg ] ]: !!lg,
      [ styles[ 'colSpanXl' + xl ] ]: !!xl,
      [ styles[ 'colSpanXxl' + xxl ] ]: !!xxl,
      [ styles[ 'pull' + capitalize(pull) ] ]: !!pull,
    },
    className
  );

  return (
    <Comp className={classname} {...other}>
      {children}
    </Comp>
  );
};

GridItem.propTypes = {};

export const GridReveal = ({ children, ...other }) => (
  <Grid {...other} as={AnimateReveal}>
    {React.Children.map(children, (child) => {
      return (
        child &&
        React.cloneElement(child, {
          as: motion.div,
        })
      );
    })}
  </Grid>
);
