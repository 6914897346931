import classNames from 'classnames';
import React, { useState, useEffect, forwardRef } from 'react';

import Alert, { alertLevel } from '../Alert/Alert';
import Icon from '../Icon/Icon';
import { AnimatePresence, motion } from '../framer-motion-custom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as styles from './Form.module.scss';
import serialiseObject from '../../../utils/serialise-object';

const themes = {
  PRIMARY: 'themePrimary',
  SECONDARY: 'themeSecondary',
  PRIMARY_FILLED: 'themePrimaryFilled',
  SECONDARY_FILLED: 'themeSecondaryFilled',
};

const Form = forwardRef(function Form({
  as: Comp = 'form',
  children,
  name,
  successMessage,
  onSubmit,
  onInvalid,
  ...other
}, ref) {
  const [ status, setStatus ] = useState({
    isSubmitted: false, 
    isSuccessful: true
  });

  function handleSubmit(e) {
    e.preventDefault();

    const isValid = e.target.checkValidity();

    if (!isValid) {
      e.target.querySelector(':invalid').scrollIntoView({
        behavior: "smooth"
      });

      if(onInvalid) {
        onInvalid(e);
      }
      
      return;
    }

    if (onSubmit) {
      onSubmit(e, setStatus);
    } else {
      const data = {};

      new FormData(e.target).forEach((value, key) => {
        data[ key ] = value;
      });

      fetch(e.target.action, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: serialiseObject(data),
      })
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            console.error(
              `Looks like there was a problem. Status Code: ${response.status}`
            );
            
            setStatus({ isSubmitted: true, isSuccessful: false });

            return;
          }

          setStatus({ isSubmitted: true, isSuccessful: true });

          window.dataLayer.push({
            'event': 'Form Submission',
            'formType': name
          });
        })
        .catch(function (err) {
          console.error('Fetch Error :-S', err);
        });
    }
  }

  let props = { ...other };

  if (name) {
    props = {
      ...props,
      [ `netlify-honeypot` ]: `bot-field`,
      [ `data-netlify` ]: `true`,
      name
    };
  }

  return (
    <Comp {...props} noValidate onSubmit={handleSubmit} ref={ref}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          key={status.isSubmitted}
        >
          {status.isSubmitted ? (
            <Alert level={status.isSuccessful ? alertLevel.SUCCESS : alertLevel.DANGER}>
              {status.isSuccessful ? successMessage : `Looks like there was a problem, please try again later.`}
            </Alert>
          ) : (
            <>
              {name && 
                <>
                  <input name="bot-field" type="hidden" />
                  <input name="form-name" type="hidden" value={name} />
                </>
              }
              
              {children}
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </Comp>
  );
});

const Row = ({ className, children }) => (
  <div className={classNames(styles.row, className)}>{children}</div>
);

const Input = ({
  id,
  className,
  label,
  placeholder,
  name,
  value = '',
  type = 'text',
  isReverse = false,
  hideLabel = false,
  theme,
  btnIcon,
  btnLabel,
  onBtnClick,
  errorMessage,
  noValidate = false,
  required = false,
  ...other
}) => {
  const [ currentValue, setValue ] = useState('');
  const [ hasFocus, setFocus ] = useState(false);
  const [ validity, setValidity ] = useState(null);
  const classname = classNames(
    styles.formfield,
    {
      [ styles.hasFocus ]: hasFocus,
      [ styles.hasValue ]: !!currentValue,
      [ styles.hasButton ]: !!btnIcon && onBtnClick,
      [ styles.isReverse ]: isReverse,
      [ styles.isSearch ]: type === 'search',
      [ styles[ theme ] ]: !!theme,
      [ styles.hideLabel ]: hideLabel,
    },
    className
  );

  const formLabel = `${label} ${
    !required && !noValidate ? '(optional)' : ''
  }`;

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleBlur(e) {
    if (!noValidate) {
      e.target.checkValidity();
    }

    setFocus(false);
  }

  function handleInvalid(e) {
    setValidity(e.target.validity);
  }

  useEffect(() => {
    setValue(value);
  }, [ value ]);

  return (
    <>
      <div className={classname}>
        {!hideLabel && (
          <label htmlFor={id}>
            {type === 'search' && (
              <Icon className={styles.searchIcon} name="search" />
            )}

            {formLabel}
          </label>
        )}

        <input
          {...other}
          type={type}
          name={name}
          id={id}
          value={currentValue}
          placeholder={placeholder}
          onInvalid={handleInvalid}
          onFocus={(e) => setFocus(true)}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label={hideLabel ? formLabel : null}
          required={required}
        />

        {!!btnIcon && onBtnClick && (
          <button
            className={styles.button}
            onClick={onBtnClick}
            type="button"
            aria-label={btnLabel}
          >
            <Icon name={btnIcon} />
          </button>
        )}
      </div>

      {validity && !validity.valid && (
        <div className={styles.validation}>
          {validity.valueMissing && `This field is required.`}
          {validity.typeMismatch && `Please enter a valid ${type}`}
        </div>
      )}

      {errorMessage && <div className={styles.validation}>{errorMessage}</div>}
    </>
  );
};

const Select = ({
  className,
  label,
  placeholder,
  name,
  id,
  value,
  isReverse = false,
  hideLabel = false,
  theme,
  options,
  onChange,
  noValidate = false,
  ...other
}) => {
  const [ currentValue, setValue ] = useState('');
  const [ hasFocus, setFocus ] = useState(false);
  const [ validity, setValidity ] = useState(null);
  const classname = classNames(
    styles.formfield,
    styles.select,
    {
      [ styles.hasFocus ]: hasFocus,
      [ styles.hasValue ]: !!currentValue,
      [ styles.isReverse ]: isReverse,
      [ styles[ theme ] ]: !!theme,
      [ styles.hideLabel ]: hideLabel,
    },
    className
  );

  const formLabel = `${label} ${
    !other.required && !noValidate ? '(optional)' : ''
  }`;

  function handleChange(e) {
    setValue(e.target.value);

    if (onChange) {
      onChange(e.target.value, e);
    }
  }

  function handleInvalid(e) {
    setValidity(e.target.validity);
  }

  useEffect(() => {
    setValue(value);
  }, [ value ]);

  return (
    <>
      <div className={classname}>
        {!hideLabel && <label htmlFor={id}>{formLabel}</label>}

        <select
          {...other}
          name={name}
          id={id}
          value={currentValue}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => setFocus(false)}
          onChange={handleChange}
          onInvalid={handleInvalid}
          aria-label={hideLabel ? formLabel : null}
        >
          {options?.map(({ label, value, children, ...other }, index) =>
            children ? (
              <optgroup label={label} key={`${value}-${index}`}>
                {children.nodes.map(({ label, value }, index) => (
                  <option value={value} key={`${value}-${index}`}>
                    {label}
                  </option>
                ))}
              </optgroup>
            ) : (
              <option value={value} key={`${value}-${index}`} {...other}>
                {label}
              </option>
            )
          )}
        </select>
      </div>

      {validity && !validity.valid && (
        <div className={styles.validation}>
          {validity.valueMissing && `This field is required.`}
        </div>
      )}
    </>
  );
};

const TextArea = ({
  id,
  className,
  label,
  placeholder,
  name,
  value = '',
  type = 'text',
  isReverse = false,
  hideLabel = false,
  theme,
  errorMessage,
  noValidate = false,
  onChange,
  ...other
}) => {
  const [ currentValue, setValue ] = useState('');
  const [ hasFocus, setFocus ] = useState(false);
  const [ validity, setValidity ] = useState(null);
  const classname = classNames(
    styles.formfield,
    {
      [ styles.hasFocus ]: hasFocus,
      [ styles.hasValue ]: !!currentValue,
      [ styles.isReverse ]: isReverse,
      [ styles[ theme ] ]: !!theme,
      [ styles.hideLabel ]: hideLabel,
    },
    className
  );

  const formLabel = `${label} ${
    !other.required && !noValidate ? '(optional)' : ''
  }`;

  function handleChange(e) {
    setValue(e.target.value);

    if (onChange) {
      onChange(e.target.value, e);
    }
  }

  function handleBlur(e) {
    if (!noValidate) {
      e.target.checkValidity();
    }

    setFocus(false);
  }

  function handleInvalid(e) {
    setValidity(e.target.validity);
  }

  useEffect(() => {
    setValue(value);
  }, [ value ]);

  return (
    <>
      <div className={classname}>
        {!hideLabel && <label htmlFor={id}>{formLabel}</label>}

        <textarea
          {...other}
          type={type}
          name={name}
          id={id}
          value={currentValue}
          placeholder={placeholder}
          onInvalid={handleInvalid}
          onFocus={(e) => setFocus(true)}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-label={hideLabel ? formLabel : null}
        />
      </div>

      {validity && !validity.valid && (
        <div className={styles.validation}>
          {validity.valueMissing && `This field is required.`}
        </div>
      )}

      {errorMessage && <div className={styles.validation}>{errorMessage}</div>}
    </>
  );
};

const Fieldset = ({
  className,
  label,
  errorMessage,
  noValidate = false,
  onChange,
  children
}) => {
  const [ validity, setValidity ] = useState(null);
  const classname = classNames(
    styles.fieldset,
    className
  );

  function handleChange(e) {
    if (!noValidate) {
      setValidity(e.target.validity);
    }

    if (onChange) {
      onChange(e.target.value, e);
    }
  }

  function handleBlur(e) {
    if (!noValidate && e.target.checkValidity) {
      e.target.checkValidity();
    }
  }

  function handleInvalid(e) {
    setValidity(e.target.validity);
  }

  return (
    <fieldset className={classname} onInvalid={handleInvalid} onBlur={handleBlur} onChange={handleChange}>
      <legend>{label}</legend>
      {children}

      {!noValidate && validity && !validity.valid && (
        <div className={styles.validation}>
          {validity.valueMissing && `This field is required.`}
        </div>
      )}

      {errorMessage && <div className={styles.validation}>{errorMessage}</div>}
    </fieldset>
  );
};

const Radio = ({
  id,
  className,
  label,
  name,
  errorMessage,
  noValidate = false,
  ...other
}) => {
  const classname = classNames(
    className
  );

  function handleBlur(e) {
    if (!noValidate) {
      e.target.checkValidity();
    }
  }

  return (
    <>
      <div className={classname}>
        <input
          {...other}
          type="radio"
          name={name}
          id={id}
          onBlur={handleBlur}
        /> <label htmlFor={id}>{label}</label>
      </div>
    </>
  );
};

const Checkbox = ({
  id,
  className,
  label,
  name,
  onChange,
  errorMessage,
  noValidate = false,
  ...other
}) => {
  const [ isValid, setIsValid ] = useState(true);
  const classname = classNames(styles.checkbox, 
    className
  );

  function handleBlur(e) {
    if (!noValidate) {
      e.target.checkValidity();
    }
  }

  function handleInvalid(e) {
    setIsValid(false);
  }

  function handleChange(e) {
    if (!noValidate) {
      setIsValid(e.target.validity.valid);
    }

    if( onChange ) {
      onChange(e);
    }
  }

  return (
    <>
      <div className={classname}>
        <input
          {...other}
          type="checkbox"
          name={name}
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onInvalid={handleInvalid}
        /> <label htmlFor={id}>{label}</label>
      </div>

      {!noValidate && !isValid && (
        <div className={styles.validation}>
          {`This field is required.`}
        </div>
      )}

      {errorMessage && <div className={styles.validation}>{errorMessage}</div>}
    </>
  );
};

const CustomDatePicker = ({
  id,
  className,
  label,
  placeholder,
  name,
  minDate = new Date(),
  date = new Date(),
  isReverse = false,
  hideLabel = false,
  theme,
  errorMessage,
  noValidate = false,
  ...other
}) => {
  const [ hasFocus, setFocus ] = useState(false);
  const [ validity, setValidity ] = useState(null);
  const classname = classNames(
    styles.datepicker,
    styles.hasButton,
    {
      [ styles.hasFocus ]: hasFocus,
      [ styles.hasValue ]: !!date,
      [ styles.isReverse ]: isReverse,
      [ styles[ theme ] ]: !!theme,
      [ styles.hideLabel ]: hideLabel,
    },
    className
  );

  const formLabel = `${label} ${
    !other.required && !noValidate ? '(optional)' : ''
  }`;

  function handleBlur(e) {
    if (!noValidate) {
      e.target.checkValidity();
    }

    setFocus(false);
  }

  function handleInvalid(e) {
    setValidity(e.target.validity);
  }
  
  return (
    <>
      <div className={classname} onInvalid={handleInvalid}>
        {!hideLabel && (
          <label htmlFor={id}>
            {formLabel}
          </label>
        )}

        <DatePicker
          name={name}
          id={id}
          minDate={minDate}
          dateFormat="P"
          placeholder={placeholder}
          onFocus={(e) => setFocus(true)}
          onBlur={handleBlur}
          aria-label={hideLabel ? formLabel : null}
          {...other}
          />

        <div className={styles.button}>
          <Icon name={"calendar"} />
        </div>
      </div>

      {validity && !validity.valid && (
        <div className={styles.validation}>
          {validity.valueMissing && `This field is required.`}
          {validity.typeMismatch && `Please enter a valid date`}
        </div>
      )}

      {errorMessage && <div className={styles.validation}>{errorMessage}</div>}
    </>
  );
};

const File = ({
  id,
  className,
  label,
  name,
  onChange,
  errorMessage,
  noValidate = false,
  ...other
}) => {
  const [ isValid, setIsValid ] = useState(true);
  const classname = classNames(
    className
  );

  function handleBlur(e) {
    if (!noValidate) {
      e.target.checkValidity();
    }
  }

  function handleInvalid(e) {
    setIsValid(false);
  }

  function handleChange(e) {
    if (!noValidate) {
      setIsValid(e.target.validity.valid);
    }

    if( onChange ) {
      onChange(e);
    }
  }

  return (
    <>
      <div className={classname}>
        <label htmlFor={id}>{label}</label>
        <input
          {...other}
          type="file"
          accept="application/pdf"
          name={name}
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onInvalid={handleInvalid}
        />
      </div>

      {!isValid && (
        <div className={styles.validation}>
          {`This field is required.`}
        </div>
      )}

      {errorMessage && <div className={styles.validation}>{errorMessage}</div>}
    </>
  );
};

const CheckboxList = ({ label, name, help, options, listId = '', onChange, required, ...other }) => {
  const [ checked, setChecked ] = useState(
    options.map(option => option.checked)
  );
  const hasChecked = checked.some(item => item === true);

  const handleChange = (position, e) => {
    const updatedCheckedState = checked.map((item, index) =>
      index === position ? !item : item
    );

    setChecked(updatedCheckedState);

    if( onChange ) {
      onChange(e);
    }
  };

  useEffect(() => {
    setChecked(
      options.map(option => option.checked)
    );
  }, [ options ])

  return (
    <Form.Fieldset label={label} {...other}>
      {help ? <p><em>{help}</em></p> : null}

      {options.map((option, index) => (
        <Form.Checkbox 
          label={option.label} 
          name={name} 
          id={`${name}${listId}${index}`} 
          value={option.value} 
          checked={checked[ index ]}
          onChange={handleChange.bind(null, index)}
          key={index}
          noValidate
          required={required && !hasChecked}
        />
      ))}
    </Form.Fieldset>
  )
};


Form.Row = Row;
Form.Input = Input;
Form.Select = Select;
Form.TextArea = TextArea;
Form.Radio = Radio;
Form.Checkbox = Checkbox;
Form.CheckboxList = CheckboxList;
Form.Fieldset = Fieldset;
Form.DatePicker = CustomDatePicker;
Form.File = File;

export { Form as default, themes };
