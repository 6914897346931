// extracted by mini-css-extract-plugin
export var accordion = "CustomAccordion-module--accordion--955c7";
export var accordionItem = "CustomAccordion-module--accordionItem--58923";
export var accordionItemButton = "CustomAccordion-module--accordionItemButton--cb978";
export var accordionItemButtonArrow = "CustomAccordion-module--accordionItemButtonArrow--a1f3c";
export var accordionItemHeading = "CustomAccordion-module--accordionItemHeading--9bc29";
export var accordionItemPanel = "CustomAccordion-module--accordionItemPanel--a0c7a";
export var accordionItemPanelInner = "CustomAccordion-module--accordionItemPanelInner--6a393";
export var isBordered = "CustomAccordion-module--isBordered--c9f5e";
export var isCompact = "CustomAccordion-module--isCompact--2dbe3";
export var isReverse = "CustomAccordion-module--isReverse--96542";