module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":125,"timeout":100000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","requestConcurrency":15,"previewRequestConcurrency":5},"url":"https://cms.prime-health.co.uk/graphql","html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"develop":{"nodeUpdateInterval":2000,"hardCacheMediaFiles":true,"hardCacheData":false},"debug":{"preview":false,"graphql":{"showQueryVarsOnError":true,"writeQueriesToDisk":false,"onlyReportCriticalErrors":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"RootQuery":{"excludeFieldNames":["email","allSettings","generalSettingsEmail"]},"Post":{"limit":5000}},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.prime-health.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WR5CFJ2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2350248228523291"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
